import { useTranslate } from "@refinedev/core";

import { Tag } from "antd";

export type InvoiceStatusProps = {
    status: "Aguardando Pagamento" | "Pagamento em Análise" | "Pagamento Aprovado" | "Reembolsado Parcial" | "Reembolsado Total" | "Cancelado";
};

export const InvoiceStatus: React.FC<InvoiceStatusProps> = ({ status }) => {
    const t = useTranslate();
    let color;

    switch (status) {
        case "Aguardando Pagamento":
            color = "red";
            break;
        case "Pagamento em Análise":
            color = "cyan";
            break;
        case "Pagamento Aprovado":
            color = "green";
            break;
        case "Reembolsado Parcial":
        case "Reembolsado Total":
            color = "blue";
            break;
        case "Cancelado":
            color = "red";
            break;
    }

    // return <Tag color={color}>{t(`enum.invoiceStatuses.${status}`)}</Tag>;
    return <Tag color={color}>{t(`${status}`)}</Tag>;
};
