import { TOKEN_KEY, API_URL, PHONE_CODES } from "./../../constants";
import { useParams } from "react-router-dom";
import {
    useShow,
    HttpError,
    IResourceComponentsProps,
    useTranslate,
    useCustom
} from "@refinedev/core";
import { dataProvider } from "./../../rest-data-provider";
import {
    useTable,
    List,
    TextField,
    getDefaultSortOrder,
    NumberField,
    DateField,
} from "@refinedev/antd";

import {
    UserOutlined,
    PhoneOutlined,
    CalendarOutlined,
    CheckOutlined,
    MailOutlined,    
} from "@ant-design/icons";
import {
    Typography,
    Avatar,
    Row,
    Col,
    Card,
    Space,
    Table,
    Grid,
    Popover,
} from "antd";
import type { ColumnsType } from 'antd/es/table';

import { InvoiceStatus } from "components";

import { IUser, ILog } from "interfaces";

const { useBreakpoint } = Grid;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { xl } = useBreakpoint();
    const { queryResult } = useShow<IUser>();

    let { data } = queryResult;
    const user = data?.data;

    const renderLogs = () => {

        const {id} = useParams();
        const urlLogs = `${API_URL}/logs?userId=${id}`;
        const { data } = useCustom<{data: any;}>({ url: urlLogs, method: "get"});
        const dataSource : any = data?.data ?? [];
        console.log(dataSource);    

        const columns: ColumnsType<ILog> = [
            // Table.EXPAND_COLUMN,
            { title: 'Ação', dataIndex: 'action', key: 'action' },            
            { title: 'Recurso', dataIndex: 'resource', key: 'resource' },
            { title: 'ID', dataIndex: 'metaId', key: 'metaId' },
            { title: 'Data', dataIndex: 'createdAt', key: 'createdAt' },
          ];

        return (
            <>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    rowKey="id"
                    rowSelection={{}}
                    expandable={{
                        // expandedRowRender: (record) => (Object.keys(record?.data ?? []))?.filter(item => !Array.isArray(item) && !(typeof item === 'object'))?.map((item: any, index: number) => {
                        //     return (<p style={{ margin: 0 }}>{item}: {record?.data[index]?.[item]}</p>);
                        // })
                    }}>
                </Table>
            </>
        );
    }

    return (
        <>
            <Row gutter={[16, 16]} style={{}}>
                <Col xl={12} lg={12} xs={12}>
                    <Card
                        bordered={false}
                        style={{
                            height: "100%",
                        }}
                    >
                        <Space
                            direction="vertical"
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <Space
                                direction="vertical"
                                style={{
                                    textAlign: "center",
                                    width: "100%",
                                }}
                            >
                                {/* <Avatar
                                    size={120}
                                    src={user?.avatar?.[0].url}
                                ></Avatar> */}
                                <Typography.Title level={3}>
                                    {user?.name}
                                </Typography.Title>
                            </Space>
                            <Space
                                direction="vertical"
                                style={{
                                    width: "100%",
                                    textAlign: xl ? "unset" : "center",
                                }}
                            >                                
                                <Typography.Text>
                                    <PhoneOutlined /> Telefone: {user?.phone}
                                </Typography.Text>
                                <Typography.Text>
                                    <MailOutlined /> Email: {user?.email}
                                </Typography.Text>                                
                                <Typography.Text>
                                    <UserOutlined /> Permissão: {user?.roleText}
                                </Typography.Text>
                                <Typography.Text>
                                    <CalendarOutlined /> Criado em: {user?.createdAt}
                                </Typography.Text>
                                <Typography.Text>
                                    <CalendarOutlined /> Último login em: {user?.lastLogin}
                                </Typography.Text>
                                <Typography.Text>
                                    <CheckOutlined />Usuário Ativado?
                                    {"  "}
                                    {user?.active
                                        ? t("users.fields.active.true")
                                        : t("users.fields.active.false")}
                                </Typography.Text>
                            </Space>
                        </Space>
                    </Card>
                </Col>
                <Col xl={12} lg={12} xs={12}>
                    <Card
                        bordered={false}
                        style={{
                            height: "100%",
                        }}
                    >
                        {renderLogs()}
                    </Card>
                </Col>
            </Row>
        </>
    );
};
