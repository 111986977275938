import { useMemo, useState, useEffect } from "react";
import { useTranslate, IResourceComponentsProps, useNavigation } from "@refinedev/core";
import {
    List,
    useTable,
    BooleanField,
    useEditableTable,
    SaveButton,
    EditButton,
    NumberField,
    DateField,
    useDrawerForm,
    CreateButton
} from "@refinedev/antd";

import { FormOutlined, MoreOutlined } from "@ant-design/icons";
import {
    Table,
    Space,
    Form,
    Button,
    Input,
    Checkbox,
    Dropdown,
    Menu,
    Avatar,
    Grid,
    Switch,
    Spin,
    Alert
} from "antd";

import { ITag, ICustomer } from "interfaces";

import "./../../css/main.css";

export const TagList: React.FC<IResourceComponentsProps> = () => {

    const {
        tableProps,
        formProps,
        isEditing,
        saveButtonProps,
        cancelButtonProps,
        setId: setEditId,
    } = useEditableTable<ITag>({
        initialSorter: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });

    const t = useTranslate();

    const TrueIcon = () => <span>✅</span>;

    const FalseIcon = () => <span>❌</span>;

    const moreMenu = (record: ITag) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    setEditId?.(record.id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
        </Menu>
    );

    const rowClassName = (record : any) => {
        return record.active ? '' : 'row-disabled';
    };

    const breakpoint = Grid.useBreakpoint();

    return (
        <List
            headerProps={{
                style: { marginTop: 20 },
                extra: (<CreateButton>Criar Grupo</CreateButton>),
            }}>            
            <Form {...formProps}>
                <Table
                    {...tableProps}
                    expandable={{
                        expandedRowRender: !breakpoint.xs ? expandedRowRender : undefined,
                    }}
                    rowKey="id"
                    onRow={(record) => ({
                        // eslint-disable-next-line
                        onClick: (event: any) => {
                            if (event.target.nodeName === "TD") {
                                setEditId && setEditId(record.id);
                            }
                        },
                    })}
                    rowClassName={rowClassName}
                >
                    <Table.Column
                        key="name"
                        dataIndex="name"
                        title={t("tags.fields.name")}
                        render={(value, data: ITag) => {
                            if (isEditing(data.id)) {
                                return (
                                    <Form.Item
                                        name="name"
                                        style={{ margin: 0 }}
                                    >
                                        <Input />
                                    </Form.Item>
                                );
                            }
                            return value;
                        }}
                    />
                    <Table.Column
                        key="customers"
                        dataIndex="customers"
                        title={`Nro de Clientes`}
                    />
                    <Table.Column
                        key="active"
                        dataIndex="active"
                        title={t("tags.fields.active")}
                        /*  render={(value) => <BooleanField value={value} />} */
                        render={(value, data: ITag) => {
                            if (isEditing(data.id)) {
                                return (
                                    <Form.Item
                                        name="active"
                                        style={{ margin: 0 }}
                                        valuePropName="checked"
                                    >
                                        <Switch checkedChildren="SIM" unCheckedChildren="NÃO"/>
                                    </Form.Item>
                                );
                            }
                            return (value ? (<p style={{"color": "#1677ff", "fontWeight": "bold"}}>SIM</p>) : (<p>NÃO</p>))
                            // return <Switch onClick={() => { setEditId(data.id); }} disabled={true} checked={value} style={{ 'pointerEvents': 'none'}} checkedChildren="SIM" unCheckedChildren="NÃO"/>;
                            // return <BooleanField
                            // trueIcon={<TrueIcon />}
                            // falseIcon={<FalseIcon />}
                            // value={value} />;
                        }}
                    />
                    <Table.Column<ITag>
                        title={"Editar"}
                        dataIndex="actions"
                        key="actions"
                        align="center"
                        render={(_text, record) => {
                            if (isEditing(record.id)) {
                                return (
                                    <Space>
                                        <SaveButton
                                            {...saveButtonProps}
                                            size="small"
                                        />
                                        <Button
                                            {...cancelButtonProps}
                                            size="small"
                                        >
                                            {t("buttons.cancel")}
                                        </Button>
                                    </Space>
                                );
                            }
                            return (
                                // <Dropdown
                                //     overlay={moreMenu(record)}
                                //     trigger={["click"]}
                                // >
                                //     <MoreOutlined
                                //         onClick={(e) => e.stopPropagation()}
                                //         style={{
                                //             fontSize: 24,
                                //         }}
                                //     />
                                // </Dropdown>                                
                                <EditButton
                                    hideText
                                    size="large"
                                    recordItemId={record.id}
                                    onClick={() => {
                                        setEditId?.(record.id);
                                    }}
                                />
                            );
                        }}
                    />
                </Table>
            </Form>
            <Alert
                message={`Clique no botão ➕ para expandir e selecionar os clientes`}
                type="info"
                showIcon
                style={{marginTop: 15}}
            />
            <Alert
                message={`Crie grupos de recebedores favoritos e utilize-os ao criar uma cobrança`}
                type="success"
                showIcon
                style={{marginTop: 15}}
            />
        </List>
    );
};

const TagCustomersTable: React.FC<{ record: ITag }> = ({ record }) => {    
    
    const t = useTranslate();
    const { edit, show } = useNavigation();
    const [loading, setLoading] = useState(false);
    const tagId = record.id;
    console.log(record);

    const { tableProps: postTableProps,
            // tableProps,
            formProps,
            isEditing,
            saveButtonProps,
            cancelButtonProps,
            setId: setEditId,
        } = useEditableTable<ICustomer>({
        resource: "customerTags",
        pagination: {
            pageSize: 10,
        },
        permanentFilter: [
            {
                field: "tagId",
                operator: "eq",
                value: record.id,
            },
        ],
        syncWithLocation: false,
    });

    const TrueIcon = () => <span>✅</span>;

    const FalseIcon = () => <span>❌</span>;

    const loader = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);            
    };

    const rowClassName = (record : any) => {
        return record.tagActive ? '' : 'row-disabled';
    };

    return (
        <List title={`${t("customers.customers")} - ${record.name}`}
            headerButtons={({ defaultButtons }) => (
            <></>
          )}>
            {(!record.active) && (<Alert
                message={`Atenção: este grupo está inativo, clique no botão "Editar" para ativá-lo e ficar disponível nas cobranças`}
                type="error"
                showIcon
                style={{margin: "15px 0px"}}
            />)}
            <Form
                {...formProps}
                // initialValues={{ tagId }}
            >
                <Table {...postTableProps}
                    rowKey="id"
                    onRow={(record) => ({
                        // eslint-disable-next-line
                        onClick: (event: any) => {
                            if (event.target.nodeName === "TD") {
                                setEditId && setEditId(`${record.id}-${tagId}`);
                            }
                        },
                    })}
                    rowClassName={rowClassName}
                >
                    <Table.Column
                        key="name"
                        dataIndex="name"
                        title={t("customers.fields.name")}
                    />
                    {/* <Table.Column
                        key="payerOrReceiverText"
                        dataIndex="payerOrReceiverText"
                        title={t("customers.fields.payerOrReceiver.title")}
                    /> */}
                    <Table.Column
                        key="tagActive"
                        dataIndex="tagActive"
                        title={t("customers.fields.tagActive.title")}                                
                        render={(value, data: ICustomer) => {                                    
                            if (isEditing(`${data.id}-${tagId}`)) {
                                return (<>
                                    <Button {...saveButtonProps} style={{ padding: 0, border: 'none', background: 'transparent' }}>                                    
                                    <Form.Item
                                        name="tagActive"                                                
                                        valuePropName="checked"
                                    >
                                        {(loading) && (<Spin spinning={loading}/>)}
                                        <Switch checkedChildren="SIM" unCheckedChildren="NÃO" onChange={loader}/>
                                    </Form.Item>
                                    </Button>
                                </>);
                            }
                            return <Switch onClick={() => { loader(); }} checked={value} disabled={true} style={{ 'pointerEvents': 'none'}} checkedChildren="SIM" unCheckedChildren="NÃO"/>
                        }}
                    />                
                    <Table.Column<ICustomer>
                        title="Editar"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="large"
                                    recordItemId={record.id}
                                    onClick={() => edit("customers", record?.id)}
                                />
                            </Space>
                        )}
                    />
                </Table>
            </Form>
        </List>
    );
};

const expandedRowRender = (record: ITag) => {
    return <TagCustomersTable record={record} />;
};
