import { useState, useEffect } from "react";
import { TOKEN_KEY, API_URL, PHONE_CODES } from "./../../constants";
import { useApiUrl, useCustom, useCustomMutation, useTranslate, useLink } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Row, Col, Input, Card, Space, Button, Typography, Tooltip, Select, Switch, Alert, Divider } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { update } from "lodash";
import MaskedInput from "antd-mask-input";
import axios from "axios";
const { Text } = Typography;

export const PreferencePage: React.FC = () => {
    
    const { t } = useTranslation();
    const Link = useLink();
    // const { formProps, saveButtonProps, onFinish } = useForm();
    // const { formProps, saveButtonProps, queryResult } = useForm<IUser>();
    const {
        formProps: formProps1,
        saveButtonProps: saveButtonProps1,
        onFinish: onFinish1,
    } = useForm();
    
    // Second form
    const {
        formProps: formProps2,
        saveButtonProps: saveButtonProps2,
        onFinish: onFinish2,
    } = useForm();

    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(null);
    const [errorMsg,setErrorMsg] = useState("");
    const [phoneMask, setPhoneMask] = useState('');
    const { mutate } = useCustomMutation();

    const phoneCodes = PHONE_CODES?.map(item => { return { label: `${item.name} (${item.code})`, value: item.iso, mask: item.mask}}) ?? [];

    const changePhoneCode = (value: any) => {
        console.log(`selected ${value}`);
        let findMask = phoneCodes?.find(item => item.value===value)?.mask;
        if(Array.isArray(findMask)){
            let mask =  findMask[findMask.length - 1]; // findMask.map((item: any) => { return { mask: item.replaceAll("9","0"), lazy: true } });
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0"));
        } else {            
            let mask = findMask; //Array.isArray(findMask) && findMask?.length ? findMask[findMask.length - 1] : findMask; // pega último mask por padrão
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0") || "(00) 00000-0000");
        }        
    };

    const handleSubmit1 = async() => {

        let values : any = formProps1.form?.getFieldsValue();
        console.log(formProps1.form?.getFieldsValue());

        let countryCode = values.countryCode;
        let phone = values.phone;
        let preferences = values.preferences;
        
        mutate({
            url: `${API_URL}/users/${userId}`,
            method: "put",
            values: {
                countryCode,
                phone,
                preferences
            },        
            successNotification: (data, values) => {
                return {
                    message: `Preferências alteradas`,
                    description: "Suas preferências foram alteradas com sucesso",
                    type: "success",
                };
            },
            errorNotification: (data, values) => {
                return {
                    message: `Oops! Houve um erro`,
                    description: "Não conseguimos atualizar suas preferências, tente novamente ou entre em contato com o nosso suporte.",
                    type: "error",
                };
            }
        });

    }

    const handleSubmit2 = async() => {

        let values : any = formProps2.form?.getFieldsValue();
        console.log(formProps2.form?.getFieldsValue());

        let password = values.password;
        let newPassword = values.newPassword;
        let newPasswordConfirm = values.newPasswordConfirm;
        
        setErrorMsg("");
        const strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

        if(!password){
            setErrorMsg("Insira sua senha atual")
            formProps2.form?.setFields([ { name: "password", errors: ["Insira sua senha atual"] }, ]);
            return false;
        }
        if(!newPassword || !newPasswordConfirm){
            setErrorMsg("Insira sua nova senha e a confirmação")
            formProps2.form?.setFields([ { name: !newPassword ? "newPassword" : "newPasswordConfirm", errors: ["Insira sua nova senha e a confirmação"] }, ]);
            return false;
        }
        if(newPassword!==newPasswordConfirm){
            setErrorMsg("As novas senhas não são iguais")
            formProps2.form?.setFields([ { name: "newPassword", errors: ["As novas senhas não são iguais"] }, ]);
            formProps2.form?.setFields([ { name: "newPasswordConfirm", errors: ["As novas senhas não são iguais"] }, ]);
            return false;
        }
        if(newPassword==password){
            setErrorMsg("Sua nova senha é a mesma que a atual, crie uma nova senha diferente")
            formProps2.form?.setFields([ { name: "password", errors: ["Sua nova senha é a mesma que a atual, crie uma nova senha diferente"] }, ]);
            formProps2.form?.setFields([ { name: "newPasswordConfirm", errors: ["Sua nova senha é a mesma que a atual, crie uma nova senha diferente"] }, ]);
            return false;
        }
        if(!strongPassword.test(newPassword)){
            setErrorMsg("Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número")
            formProps2.form?.setFields([ { name: "newPassword", errors: ["Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número"] }, ]);
            return false;
        }

        mutate({
            url: `${API_URL}/auth/password/edit`,
            method: "post",
            values: {
                password,
                newPassword,
                newPasswordConfirm
            },        
            successNotification: (data, values) => {
                formProps2.form?.setFields([ { name: "password", value: null }, { name: "newPassword", value: null }, { name: "newPasswordConfirm", value: null } ]);                
                return {
                    message: `Senha alterada`,
                    description: "Sua nova senha foi cadastrada com sucesso",
                    type: "success",
                };
            },
            errorNotification: (data, values) => {
                return {
                    message: `Oops! Houve um erro`,
                    description: "Não conseguimos atualizar sua senha, tente novamente ou entre em contato com o nosso suporte.",
                    type: "error",
                };
            }
        });

    }

    useEffect(() => {        
        // Pega dados pessoais/preferências direto da custom endpoint
        const fetchData = async () => {  
            let response = await axios.get(`${API_URL}/auth/me`,
                {
                    headers:{
                    'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                    'companyId': localStorage.getItem('companyId') ?? '0',
                    }
            });
                        
            if(response?.data){
                console.log("API RESPONSE",response);
                setUser(response?.data?.user);
            } else {
                console.log("API ERROR",response);
            }   
        }
        fetchData();
    },[]);

    useEffect(() => {
        if (user) {
            // @ts-ignore */}
            setUserId(user?.id);
            formProps1?.form?.setFieldsValue(user);
            console.log({user});
        }
    }, [user, formProps1?.form]);

    if(!userId) return (<p>Carregando informações, por favor aguarde...</p>)

    return (<>
        <Edit
            headerProps={{
                extra: <></>,
            }}
            breadcrumb={<></>}
            footerButtons={<></>}
        >            
                <Row gutter={[64, 0]} wrap>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form
                            {...formProps1}
                            layout="vertical"                            
                            initialValues={{
                                name: '',
                                email: '',
                                countryCode: "+55",
                                phone: '',
                                preferences: {
                                    sendWhatsApp: false,
                                    sendEmail: false,
                                }
                            }}
                            onFinish={handleSubmit1}
                        >
                                    <Row>
                                        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Alterar preferências:<br/></Text>
                                    </Row>
                                    <Form.Item
                                        label={t("users.fields.name")}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>     
                                    <Form.Item
                                        label={t("users.fields.email")}
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                type: "email",
                                            },
                                        ]}
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                    <Form.Item
                                        label={`Receber notificações por Email?`}
                                        name={['preferences',"sendEmail"]}
                                        valuePropName="checked" // This is important for Switch component
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Switch checkedChildren="SIM" unCheckedChildren="NÃO"/>
                                    </Form.Item>
                                    <Divider/>
                                    <Space.Compact style={{ width: '100%' }}>
                                    <Form.Item
                                        label={"País"}
                                        name="countryCode"
                                        style={{ width: '40%' }}
                                    >
                                        <Select
                                            showSearch
                                            disabled={false}
                                            // defaultValue="BR"
                                            options={phoneCodes}
                                            onChange={changePhoneCode}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("users.fields.phone")}
                                        name="phone"
                                        style={{ width: '60%' }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            {
                                                // ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                      console.log(value);
                                                      if ((!value?.replace(/\D/g,'') || Number(value?.replace(/\D/g,''))===0) || String(value?.replace(/\D/g,''))?.includes("99999")) {
                                                        return Promise.reject('Oops! Insira um celular válido');
                                                      }
                                                      return Promise.resolve();
                                                    },
                                                // }),
                                            }
                                        ]}
                                    >
                                        <MaskedInput
                                                disabled={false}
                                                mask={phoneMask || "(00) 00000-0000"}
                                                >
                                                {/* 
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore */}
                                                {/* {(props: InputProps) => <Input {...props} />} */}
                                        </MaskedInput>                             
                                    </Form.Item>
                                    </Space.Compact>   
                                    <Form.Item
                                        label={`Receber notificações por WhatsApp?`}
                                        name={['preferences',"sendWhatsApp"]}           
                                        valuePropName="checked" // This is important for Switch component                             
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Switch checkedChildren="SIM" unCheckedChildren="NÃO"/>
                                    </Form.Item>                                          
                                    <Form.Item>
                                        <Button
                                        type="primary"
                                        htmlType="submit"
                                        icon={<SaveOutlined />} 
                                        // onClick={() => updatePassword()}
                                        block
                                        size="large">
                                            Salvar Preferências
                                        </Button>
                                    </Form.Item>      
                                    <Alert
                                        message={"Notificações: incluem alertas de cobranças, cadastro de clientes e alterações importantes"}
                                        type="info"
                                        showIcon
                                        style={{marginTop: 15, marginBottom: 15}}
                                    />                                                              
                        </Form>
                    </Col>
                                 
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form
                            {...formProps2}
                            // form={form}
                            layout="vertical"
                            initialValues={{
                                active: true,
                                // ...formProps
                            }}
                            onFinish={handleSubmit2}
                        >       
                            <Row>
                                <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Alterar senha de acesso:<br/></Text>
                            </Row>
                            <Form.Item
                                label={t("pages.updatePassword.fields.password")}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                tooltip={"Sua senha atual"}
                            >
                                <Input.Password />
                            </Form.Item> 
                            <Divider/>
                            <Form.Item
                                label={t("pages.updatePassword.fields.newPassword")}
                                name="newPassword"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                tooltip={"Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número"}
                            >
                                    <Input.Password />
                            </Form.Item>       
                            <Form.Item
                                label={t("pages.updatePassword.fields.confirmNewPassword")}
                                name="newPasswordConfirm"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                tooltip={"Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número"}
                            >
                                <Input.Password />
                            </Form.Item> 
                            {errorMsg && (
                                <Space direction="vertical" style={{ width: '100%', marginBottom: 15 }}>
                                    <Alert
                                        message="ATENÇÃO:"
                                        description={errorMsg}
                                        type="error"
                                        showIcon
                                    />
                                </Space>
                            )}       
                            <Form.Item>
                                <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SaveOutlined />} 
                                // onClick={() => updatePassword()}
                                block
                                size="large">
                                    Alterar Senha
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    
                </Row>
        </Edit>
        </>);
};
