import { useTranslate } from "@refinedev/core";

import { Tag } from "antd";

export type AccountStatusProps = {
    status: "Em Análise" | "Aprovado" | "Negado" | "Verificada" | "Inválida > Revisar" | "Enviar Documentos" | "Documentos em Análise" | "Revisar Dados" | "Análise Inicial" | "Pendente" | "Dados Pendentes" | "Habilitada";
};

export const AccountStatus: React.FC<AccountStatusProps> = ({ status }) => {
    const t = useTranslate();
    let color;

    switch (status) {
        case "Em Análise":
        case "Análise Inicial":
        case "Documentos em Análise":
        case "Enviar Documentos":
            color = "yellow";
            break;
        case "Aprovado":
        case "Verificada":
        case "Habilitada":
            color = "green";
            break;
        case "Pendente":
        case "Negado":
        case "Dados Pendentes":
        case "Inválida > Revisar":
        case "Revisar Dados":
            color = "red";
            break;
    }

    // return <Tag color={color}>{t(`enum.invoiceStatuses.${status}`)}</Tag>;
    return <Tag color={color}>{t(`${status}`)}</Tag>;
};