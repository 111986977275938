import { useNavigation, useTranslate } from "@refinedev/core";
import { useTable, DateField } from "@refinedev/antd";
import { Typography, Table, Avatar, Space, Tag } from "antd";
import {
    // Table.Column,
    Price,
    InvoiceId,
    Title,
    TitleWrapper,
} from "./styled";

import { InvoiceActions, InvoiceStatus } from "components";
import { currencyFormatter } from "./../../../functions";

import { IInvoice } from "interfaces";

const { Text, Paragraph } = Typography;

export const RecentInvoices: React.FC = () => {
    const t = useTranslate();
    const { tableProps } = useTable<IInvoice>({
        resource: "invoices",
        initialSorter: [
            {
                field: "createdAt",
                order: "desc",
            },
        ],
        initialPageSize: 4,
        permanentFilter: [
            {
                field: "statusText",
                operator: "eq",
                value: "Pending",
            },
        ],
        syncWithLocation: false,
    });

    const { show } = useNavigation();

    return (
        <Table
            {...tableProps}
            pagination={{ ...tableProps.pagination, simple: true }}
            showHeader={false}
            rowKey="id"
            onRow={(record : any) => {
                return {
                    onClick: () => {
                        show("invoices", record.id);
                    },
                };
            }}
        >
            <Table.Column
                key="summary"
                render={(_, record : any) => (
                    <TitleWrapper>
                        <Title strong>{record?.customer?.name}</Title>
                        {/* <Paragraph
                            ellipsis={{
                                rows: 2,
                                tooltip: record?.statusText,
                                symbol: <span>...</span>,
                            }}
                        >
                            {record?.statusText}                            
                        </Paragraph> */}

                        <InvoiceId
                            strong
                            // onClick={() => {
                            //     show("invoices", record.id);
                            // }}
                        >
                            #{record?.id}
                        </InvoiceId>
                    </TitleWrapper>
                )}
            />     

            <Table.Column
                key="statusText"
                dataIndex="statusText"
                render={(value) => {
                    return <InvoiceStatus status={value} />;
                }}
            />            
            <Table.Column<IInvoice>
                dataIndex="gross"
                render={(value, record) => (
                    <Space
                        size="large"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        {currencyFormatter(value)}
                        {/* <Tag color="orange">
                            {t(`enum.orderStatuses.${record.statusText}`)}
                        </Tag> */}
                    </Space>
                )}
            />
            <Table.Column
                key="createdAt"
                dataIndex="createdAt"
                // title={t("customers.fields.createdAt")}
                render={(value) => (
                    <Space
                        size="large"
                        style={{
                            display: "flex",
                            minWidth: 100,
                            justifyContent: "space-between",
                        }}
                    >
                        <DateField value={value} format="LLL" />
                    </Space>
                )}
            />
            {/* <Table.Column<IInvoice>
                fixed="right"
                key="actions"
                align="center"
                render={(_, record) => <InvoiceActions record={record} />}
            /> */}
        </Table>
    );
};
