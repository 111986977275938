export const replaceAccents = (str : any) => {

    if(!str) return null;
    // Normalize the string and remove combining diacritical marks
    const normalizedStr = str?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');

    // Replace accented characters with non-accented equivalents
    const replacedStr = normalizedStr?.replace(/[áàäâ]/gi, 'a')
                                    .replace(/[éèëê]/gi, 'e')
                                    .replace(/[íìïî]/gi, 'i')
                                    .replace(/[óòöô]/gi, 'o')
                                    .replace(/[úùüû]/gi, 'u')
                                    .replace(/[ç]/gi, 'c');

    return replacedStr;
    
}

export function currencyFormatter(value : any){
    
    if(!value) return;

    return new Intl.NumberFormat("pt-br", {
      style: "currency",
      currency: "BRL"
    }).format(value);
}

export function validateCPFOrCNPJ(number : string) {
  const cleanedNumber = number.replace(/[^\d]/g, ''); // Remove non-digit characters

  if (cleanedNumber.length === 11) {
    return validateCPF(cleanedNumber);
  } else if (cleanedNumber.length === 14) {
    return validateCNPJ(cleanedNumber);
  } else {
    return false; // Invalid length
  }
}

export function validateCPF(cpf : string) {
  const cpfDigits = cpf.split('').map(Number);

  if (cpfDigits.length !== 11 || cpfDigits.some(digit => isNaN(digit))) {
    console.log("invalid cpf 1");
    return false; // Invalid CPF length or non-numeric characters
  }

  const [a, b, c, d, e, f, g, h, i, j, k] = cpfDigits;

  const sum = (
    (10 * a) +
    (9 * b) +
    (8 * c) +
    (7 * d) +
    (6 * e) +
    (5 * f) +
    (4 * g) +
    (3 * h) +
    (2 * i)
  );

  const mod = sum % 11;
  const firstDigit = mod < 2 ? 0 : 11 - mod;

  if (firstDigit !== j) {
    console.log("invalid cpf 2");
    return false; // Invalid CPF
  }

  const sum2 = (
    (11 * a) +
    (10 * b) +
    (9 * c) +
    (8 * d) +
    (7 * e) +
    (6 * f) +
    (5 * g) +
    (4 * h) +
    (3 * i) +
    (2 * j)
  );

  const mod2 = sum2 % 11;
  const secondDigit = mod2 < 2 ? 0 : 11 - mod2;

  console.log("invalid cpf 3");
  return secondDigit === k; // Valid CPF
}

export function validateCNPJ(cnpj : string) {
  const cnpjDigits = cnpj.split('').map(Number);

  if (cnpjDigits.length !== 14 || cnpjDigits.some(digit => isNaN(digit))) {
    return false; // Invalid CNPJ length or non-numeric characters
  }

  const [a, b, c, d, e, f, g, h, i, j, k, l, m, n] = cnpjDigits;

  const sum = (
    (5 * a) +
    (4 * b) +
    (3 * c) +
    (2 * d) +
    (9 * e) +
    (8 * f) +
    (7 * g) +
    (6 * h) +
    (5 * i) +
    (4 * j) +
    (3 * k) +
    (2 * l)
  );

  const mod = sum % 11;
  const firstDigit = mod < 2 ? 0 : 11 - mod;

  if (firstDigit !== m) {
    return false; // Invalid CNPJ
  }

  const sum2 = (
    (6 * a) +
    (5 * b) +
    (4 * c) +
    (3 * d) +
    (2 * e) +
    (9 * f) +
    (8 * g) +
    (7 * h) +
    (6 * i) +
    (5 * j) +
    (4 * k) +
    (3 * l) +
    (2 * m)
  );

  const mod2 = sum2 % 11;
  const secondDigit = mod2 < 2 ? 0 : 11 - mod2;

  return secondDigit === n; // Valid CNPJ
}

export function validateEmail(email : string) {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const isRegexValid = emailRegex.test(email);

  if (!isRegexValid) {
    return false;
  }

  const [localPart, domain] = email.split('@');
  const domainParts = domain.split('.');
  
  // Check if the local part and domain meet the length requirements
  if (localPart.length > 64 || domain.length > 255) {
    return false;
  }
  
  // Check if any domain part is empty or longer than 63 characters
  if (domainParts.some(part => part.length === 0 || part.length > 63)) {
    return false;
  }
  
  // Check if the domain ends with a valid top-level domain (TLD)
  const tldRegex = /^[A-Za-z]{2,}$/;
  const topLevelDomain = domainParts[domainParts.length - 1];
  if (!tldRegex.test(topLevelDomain)) {
    return false;
  }

  return true;
}

/*
export function validatePhoneNumber(phoneNumber : string, countryCode = "BR") {
  const { PhoneNumberUtil } = require('google-libphonenumber');
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const parsedNumber = phoneUtil.parse(phoneNumber, countryCode);
    const isValid = phoneUtil.isValidNumber(parsedNumber);
    return isValid;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
*/