import { TOKEN_KEY, API_URL } from "./../../constants";
import { HttpError } from "@refinedev/core";
import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async config => {
  
  if(config && config.headers){    
    let token = localStorage.getItem(TOKEN_KEY);
    let companyId = localStorage.getItem("companyId");
    if(token) config.headers.Authorization = `Bearer ${token}` // you may need "Bearer" here
    if(companyId) config.headers.companyId = `${companyId}`; // TO-DO

    return config
  }    

  return;
});


axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

export { axiosInstance };
