import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import { Create, useForm } from "@refinedev/antd";

import {
    Form,
    Input,
    Avatar,
    Row,
    Col,
    Typography,
    Space,
    Radio,
    InputProps,
} from "antd";

const { Text } = Typography;

import { ITag } from "interfaces";

export const TagCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm<ITag>();

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
        >
            <Form
                {...formProps}
                layout="vertical"
                initialValues={{
                    active: true,
                }}
            >
                <Row gutter={[64, 0]} wrap>
                    {/* <Col xs={24} lg={6}>
                        <Space
                            direction="vertical"
                            align="center"
                            style={{
                                width: "100%",
                                textAlign: "center",
                                marginBottom: "16px",
                            }}
                        >
                            <Avatar
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    maxWidth: "256px",
                                }}
                                src="/images/default-store-img-lg.png"
                                alt="User Location"
                            />
                            <Text
                                strong
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {t("users.selectLocation")}
                            </Text>
                        </Space>
                    </Col> */}
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("tags.fields.name")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>                                                                       
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
