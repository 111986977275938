import {
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useExport,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    useSelect,
    ExportButton,
    ShowButton,
    RefreshButton,
    CreateButton
} from "@refinedev/antd";

import { SearchOutlined } from "@ant-design/icons";

import {
    Table,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Space
} from "antd";

import dayjs from "dayjs";

import { InvoiceStatus, InvoiceActions } from "components";

import {
    IInvoice,
    ICustomer,
    IInvoiceFilterVariables,
    IInvoiceStatus,
    IUser,
} from "interfaces";
import { useMemo } from "react";

export const InvoiceList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, searchFormProps, filters } = useTable<
        IInvoice,
        HttpError,
        IInvoiceFilterVariables
    >({
        // resource: "payers",
        initialSorter: [
            {
                field: "createdAt",
                order: "desc",
            },
        ],
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, customer, user, createdAt, status } = params;

            filters.push({
                field: "q",
                operator: "eq",
                value: q,
            });

            filters.push({
                field: "customerId",
                operator: "eq",
                value: customer,
            });

            filters.push({
                field: "status",
                operator: "in",
                value: status,
            });

            filters.push(
                {
                    field: "createdAt",
                    operator: "gte",
                    value: createdAt
                        ? createdAt[0].startOf("day").toISOString()
                        : undefined,
                },
                {
                    field: "createdAt",
                    operator: "lte",
                    value: createdAt
                        ? createdAt[1].endOf("day").toISOString()
                        : undefined,
                },
            );

            return filters;
        },
    });

    const t = useTranslate();
    const { show } = useNavigation();

    const { isLoading, triggerExport } = useExport<IInvoice>({
        sorter,
        filters,
        pageSize: 50,
        maxItemCount: 50,
        mapData: (item) => {
            return {
                id: item.id,
                // amount: item.amount,
                // orderNumber: item.orderNumber,
                status: item.statusText,
                // store: item.customer.title,
                // user: item.user.firstName,
            };
        },
    });

    const Actions: React.FC = () => (
        <ExportButton onClick={triggerExport} loading={isLoading} />
    );

    return (
        <Row gutter={[16, 16]}>
            <Col
                xl={6}
                lg={24}
                xs={24}
                style={{
                    marginTop: "52px",
                }}
            >
                <Card title={t("invoices.filter.title")}>
                    <Filter
                        formProps={searchFormProps}
                        filters={filters || []}
                    />
                </Card>
            </Col>
            <Col xl={18} xs={24}>
                <List
                    headerProps={{
                        extra: 
                        // <RefreshButton
                        //     resource="invoices"
                        // />
                        <CreateButton>Criar Cobrança</CreateButton>
                    }}
                >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    show("invoices", record.id);
                                },
                            };
                        }}
                    >
                        <Table.Column
                            key="id"
                            dataIndex="id"
                            title={t("invoices.fields.id")}
                            render={(value) => <TextField value={value} />}
                        />
                        <Table.Column
                            key="statusText"
                            dataIndex="statusText"
                            title={t("users.fields.statusText")}
                            render={(value) => {
                                return <InvoiceStatus status={value} />;
                            }}
                        />                        
                        <Table.Column
                            key="customer.name"
                            dataIndex={["customer", "name"]}
                            title={t("invoices.fields.customer")}
                        />
                        {/* <Table.Column<IInvoice>
                            key="statusText"
                            dataIndex={["status", "text"]}
                            title={t("invoices.fields.status")}
                            render={(value) => {
                                return <InvoiceStatus status={value} />;
                            }}
                            defaultSortOrder={getDefaultSortOrder(
                                "statusText",
                                sorter,
                            )}
                            sorter
                        /> */}
                        <Table.Column
                            align="left"
                            key="gross"
                            dataIndex="gross"
                            title="Valor da Cobrança"
                            defaultSortOrder={getDefaultSortOrder(
                                "gross",
                                sorter,
                            )}
                            sorter
                            render={(value) => {
                                return (
                                    <NumberField
                                        options={{
                                            currency: "BRL",
                                            style: "currency",
                                        }}
                                        locale="pt-BR" 
                                        value={value}
                                    />
                                );
                            }}
                        />
                        {/* <Table.Column
                            key="store.id"
                            dataIndex={["store", "title"]}
                            title={t("invoices.fields.store")}
                        /> */}
                        {/* <Table.Column
                            key="user.fullName"
                            dataIndex={["user", "fullName"]}
                            title={t("invoices.fields.user")}
                        /> */}
                        {/* <Table.Column<IInvoice>
                            key="products"
                            dataIndex="products"
                            title={t("invoices.fields.products")}
                            render={(_, record) => (
                                <Popover
                                    content={
                                        <ul>
                                            {record.products.map((product) => (
                                                <li key={product.id}>
                                                    {product.name}
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                    title="Products"
                                    trigger="hover"
                                >
                                    {t("invoices.fields.itemsAmount", {
                                        amount: record.products.length,
                                    })}
                                </Popover>
                            )}
                        /> */}
                        <Table.Column
                            key="createdAt"
                            dataIndex="createdAt"
                            title={t("invoices.fields.createdAt")}
                            render={(value) => (
                                <DateField value={value} format="LLL" />
                            )}
                            sorter
                        />
                        {/* <Table.Column<IInvoice>
                            fixed="right"
                            title={t("table.actions")}
                            dataIndex="actions"
                            key="actions"
                            align="center"
                            render={(_value, record) => (
                                <InvoiceActions record={record} />
                            )}
                        /> */}
                        <Table.Column<IInvoice>
                        title="Visualizar"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                /> */}
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
    props,
) => {
    const t = useTranslate();

    const { formProps, filters } = props;
    const { selectProps: customerSelectProps } = useSelect<ICustomer>({
        resource: "customers",
        optionLabel: "name",
        optionValue: "id",
        defaultValue: getDefaultFilter("customer.id", filters),
    });

    const { selectProps: invoiceSelectProps } = useSelect<IInvoiceStatus>({
        resource: "invoiceStatuses",
        optionLabel: "statusText",
        optionValue: "status",
        defaultValue: getDefaultFilter("statusText", filters),
    });

    // const { selectProps: userSelectProps } = useSelect<IUser>({
    //     resource: "users",
    //     optionLabel: "name",
    //     defaultValue: getDefaultFilter("user.id", filters),
    // });

    const { RangePicker } = DatePicker;

    const createdAt = useMemo(() => {
        const start = getDefaultFilter("createdAt", filters, "gte");
        const end = getDefaultFilter("createdAt", filters, "lte");

        const startFrom = dayjs(start);
        const endAt = dayjs(end);

        if (start && end) {
            return [startFrom, endAt];
        }
        return undefined;
    }, [filters]);

    return (
        <Form
            layout="vertical"
            {...formProps}
            initialValues={{
                q: getDefaultFilter("q", filters),
                customer: getDefaultFilter("customer.id", filters),
                // user: getDefaultFilter("user.id", filters),
                status: getDefaultFilter("statusText", filters, "in"),
                createdAt,
            }}
        >
            <Row gutter={[10, 0]} align="bottom">
                <Col xl={24} md={8} sm={12} xs={24}>
                    <Form.Item label={t("invoices.filter.search.label")} name="q">
                        <Input
                            placeholder={t("invoices.filter.search.placeholder")}
                            prefix={<SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col xl={24} md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t("invoices.filter.status.label")}
                        name="status"
                    >
                        <Select
                            {...invoiceSelectProps}
                            allowClear
                            mode="multiple"
                            placeholder={t("invoices.filter.status.placeholder")}
                        />
                    </Form.Item>
                </Col>
                <Col xl={24} md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t("invoices.filter.customer.label")}
                        name="customer"
                    >
                        <Select
                            {...customerSelectProps}
                            allowClear
                            placeholder={t("invoices.filter.customer.placeholder")}
                        />
                    </Form.Item>
                </Col>
                {/* <Col xl={24} md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t("invoices.filter.user.label")}
                        name="user"
                    >
                        <Select
                            {...userSelectProps}
                            allowClear
                            placeholder={t("invoices.filter.user.placeholder")}
                        />
                    </Form.Item>
                </Col> */}
                <Col xl={24} md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t("invoices.filter.createdAt.label")}
                        name="createdAt"
                    >
                        <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col xl={24} md={8} sm={12} xs={24}>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            size="large"
                            block
                        >
                            {t("invoices.filter.submit")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
