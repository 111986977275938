import { useState, useEffect } from "react";
import { TOKEN_KEY, API_URL } from "./../../constants";
import { currencyFormatter } from "./../../functions";
import { useTranslate, useCustom, IResourceComponentsProps } from "@refinedev/core";
import { Row, Col, Card, Typography, Checkbox, Button, Statistic } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import {
    DailyRevenue,
    DailyOrders,
    NewCustomers,
    RecentInvoices,
} from "components";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation();

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    // Pega Empresa da endpoint
    const companyId = localStorage.getItem("companyId");
    const url = `${API_URL}/metrics`;
    const { data } = useCustom<{data: any;}>({ url, method: "get" });
    const metrics : any = data?.data;
    console.log(metrics);

    let canAccess = ["admin","owner"].includes(localStorage.getItem("role") ?? "");

    return (
        <Row gutter={[16, 16]}>
            {/*
            <Col md={24}>
                <Row gutter={[16, 16]}>
                    <Col xl={10} lg={24} md={24} sm={24} xs={24}>
                        <Card
                            bodyStyle={{
                                padding: 10,
                                paddingBottom: 0,
                            }}
                            style={{
                                background: "url(images/daily-revenue.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "right",
                                backgroundSize: "cover",
                            }}
                        >
                            <DailyRevenue />
                        </Card>
                    </Col>
                    <Col xl={7} lg={12} md={24} sm={24} xs={24}>
                        <Card
                            bodyStyle={{
                                padding: 10,
                                paddingBottom: 0,
                            }}
                            style={{
                                background: "url(images/daily-order.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "right",
                                backgroundSize: "cover",
                            }}
                        >
                            <DailyOrders />
                        </Card>
                    </Col>
                    <Col xl={7} lg={12} md={24} sm={24} xs={24}>
                        <Card
                            bodyStyle={{
                                padding: 10,
                                paddingBottom: 0,
                            }}
                            style={{
                                background: "url(images/new-orders.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "right",
                                backgroundSize: "cover",
                            }}
                        >
                            <NewCustomers />
                        </Card>
                    </Col>
                </Row>
            </Col>
            */}
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                <Card
                    bodyStyle={{
                        // height: 550,
                        overflowY: "scroll",
                    }}
                    title={
                        <Text strong style={{ textTransform: "capitalize" }}>
                            {t("dashboard.metrics.title")}
                        </Text>
                    }
                >                    
                    {metrics &&
                        (canAccess) &&
                        (Number(metrics?.customers)<=0 || Number(metrics?.companyaccounts)<=0  || Number(metrics?.invoices)<=0 || Number(metrics?.users)<=1) && 
                        (
                        <>
                            <Text style={{fontSize: 14}}>Realize as atividades a seguir para conhecer todas as funcionalidades:</Text>                    
                            <br />
                            <br />
                            {(Number(metrics?.customers)>0 ? <TrueIcon></TrueIcon> : <FalseIcon></FalseIcon>)} Cadastar clientes
                            {Number(metrics?.customers)<=0 && (  <Link to="/customers">{   } Detalhes</Link>)}
                            <br />
                            {(Number(metrics?.companyaccounts)>0 ? <TrueIcon></TrueIcon> : <FalseIcon></FalseIcon>)} Cadastar conta bancária
                            {Number(metrics?.companyaccounts)<=0 && (  <Link to="/companyAccounts">{   } Detalhes</Link>)}
                            <br />
                            {(Number(metrics?.invoices)>0 ? <TrueIcon></TrueIcon> : <FalseIcon></FalseIcon>)} Criar uma cobrança
                            {Number(metrics?.invoices)<=0 && (  <Link to="/invoices/create">{   } Detalhes</Link>)}
                            <br />
                            {(Number(metrics?.users)>1 ? <TrueIcon></TrueIcon> : <FalseIcon></FalseIcon>)} Cadastar funcionários
                            {Number(metrics?.users)<=1 && (  <Link to="/users">{   } Detalhes</Link>)}
                        </>
                    )}
                    {metrics &&
                        (Number(metrics?.customers)>0 && Number(metrics?.companyaccounts)>0 && Number(metrics?.invoices)>0 && Number(metrics?.users)>1)
                        && (
                        <>
                            <Statistic title="Clientes cadastrados" value={Number(metrics?.customers)} />                            
                            <Statistic title="Contas bancárias cadastradas" value={Number(metrics?.companyaccounts ?? 0)} />                            
                            <Statistic title="Cobranças pagas" value={Number(metrics?.invoices_paid ?? 0)} />
                            <Statistic title="Valor transacionado" value={`${currencyFormatter(metrics?.invoices_paid_total ?? 0)}`} />
                            <Statistic title="Funcionários criados" value={Number(metrics?.users ?? 0)} />

                            {/* <Text style={{fontSize:16, fontWeight: 600}}>Clientes cadastrados:</Text> */}
                            {/* <br />
                            <Text>{Number(metrics?.customers)}</Text> */}
                            {/* <br /> */}
                            {/* <Text style={{fontSize:16, fontWeight: 600}}>Contas bancárias cadastradas:</Text> */}                            
                            {/* <br />
                            <Text>{Number(metrics?.companyaccounts)}</Text> */}
                            {/* <br /> */}
                            {/* <Text style={{fontSize:16, fontWeight: 600}}>Cobranças pagas:</Text> */}
                            {/* <br /> */}
                            {/* <Text>{Number(metrics?.invoices_paid ?? 0)}</Text> */}
                            {/* <br /> */}
                            {/* <Text style={{fontSize:16, fontWeight: 600}}>Valor transacionado:</Text>
                            <br /> */}
                            {/* <Text>R$ {Math.round(metrics?.invoices_paid_total ?? 0).toFixed(0)}</Text> */}
                            {/* <br /> */}
                            {/* <Text style={{fontSize:16, fontWeight: 600}}>Funcionários criados:</Text> */}
                            {/* <br /> */}
                            {/* <Text>{Number(metrics?.users)}</Text> */}
                        </>
                        )
                    }
                </Card>
            </Col>
            <Col xl={16} lg={16} md={12} sm={24} xs={24}>
                <Card
                    title={<>
                        <Text strong>{t("dashboard.recentInvoices.title")}</Text>
                        {(canAccess) && (
                        <Link to="/invoices/create">
                            <Button
                                style={{
                                    // display: "flex",
                                    // alignItems: "center",
                                    // justifyContent: "center",
                                    float: "right"
                                }}
                                type="primary"
                                size="small"
                                icon={<PlusOutlined />}
                            >                            
                                Criar Cobrança                            
                            </Button>
                        </Link>
                        )}
                        </>
                    }
                >                    
                    <RecentInvoices />
                </Card>
            </Col>
            {/* <Col xl={7} lg={8} md={24} sm={24} xs={24}>
                <Card
                    title={
                        <Text strong>{t("dashboard.trendingMenus.title")}</Text>
                    }
                >
                    <TrendingMenu />
                </Card>
            </Col> */}
        </Row>
    );
};
