import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams, useLocation } from 'react-router-dom';
import { TOKEN_KEY, API_URL, PHONE_CODES, BANK_CODES } from "../../../constants";
import {
  RegisterPageProps,
  RegisterFormTypes,
  useRouterType,
  useLink,
  useActiveAuthProvider,
  useTranslate,
  useRouterContext,
  useRegister,
  useCustomMutation
} from "@refinedev/core";
import { ThemedTitle, useForm } from "@refinedev/antd";
import {
  layoutStyles,
  // containerStyles,
  titleStyles,
  headStyles,
  bodyStyles,
} from "./styles";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  LayoutProps,
  CardProps,
  FormProps,
  Divider,
  theme,
  Space,
  Select,
  Radio,
  Alert,
  DatePicker,
  DatePickerProps,
  InputNumber,
  Checkbox,
  Steps,
  Modal,
  Spin
} from "antd";
import MaskedInput from "antd-mask-input";
import { BikeWhiteIcon, FineFoodsIcon, StepsProfileStatus, ProfileStatus } from "components"
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined, BankOutlined, HomeOutlined, DollarOutlined } from '@ant-design/icons';
const { Text, Title } = Typography;
const { useToken } = theme;
import axios from "axios";
import { replaceAccents, validateCPFOrCNPJ, validateEmail } from "../../../functions";
import BankAccountValidator from "br-bank-account-validator";
type RegisterProps = RegisterPageProps<LayoutProps, CardProps, FormProps>;
/**
 * **refine** has register page form which is served on `/register` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#register} for more details.
 */
export const ProfileRegister: React.FC<RegisterProps> = ({
  providers,
  loginLink,
  wrapperProps,
  contentProps,
  renderContent,
  // formProps,
  title,
}) => {
  const location = useLocation();
  const {registerType, id} = useParams();    
  const queryParams = new URLSearchParams(location.search);  
  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const [showForm,setShowForm] = useState(false); 
  const [phoneMask, setPhoneMask] = useState(null as any);
  const { token } = useToken();  
  const { formProps, onFinish } = useForm();    
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();  
  const [customerType, setCustomerType] = useState("");
  const [company, setCompany] : any = useState({});
  const [newCustomer, setNewCustomer] : any = useState(true);
  const { mutate } = useCustomMutation();
  const [open, setOpen] = useState(false);
  const [statusStep, setStatusStep] = useState(0);
  const [asaasDocuments, setAsaasDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;
  // const [form] = Form.useForm<RegisterFormTypes>();
  const cpfCnpj = queryParams.get('cpfCnpj');

  console.log({registerType, id, cpfCnpj});

  const addErrorMsg = (newMessage: string) => setErrorMsg(prev => prev.includes(newMessage) ? prev : [...prev, newMessage]);

  const handleTypeChange = (e: any) => {
    setCustomerType(e.target.value);
    onNameChange(e.target.value);
  };

  const onNameChange = (value: any) => {

    const type = formProps.form?.getFieldValue("type");
    const name = formProps.form?.getFieldValue("name");
    if(type==="individual" && name){
        formProps.form?.setFields([
            {
                name: "razaoSocial", // required
                value: name
            },
        ]);
    }        
  }

  const findCpfCnpj = async(e: any) => {
    
    let value = e?.value || e?.target?.value; // ?.replace(/\D/g,'');
    let type = formProps.form?.getFieldValue("type") || e?.type;
    console.log({e, type, value});    

    if(
      ((type==="individual" || !type) && value?.replace(/\D/g,'')?.length>=11)
      ||
      (type==="corporation" && value?.replace(/\D/g,'')?.length>=14)
    ){      

      setLoading(true);

      let response = await axios.post(`${API_URL}/public/cpfCnpj`, {
        entityType: type,
        cpfCnpj: value,
        companyHash: id,
        type: registerType
      });

      console.log(response);
      let result = response?.data;

      setShowForm(true);
      setLoading(false);

      if(result?.profileId){
        // alert("Atenção! Esse CPF/CNPJ já possui cadastro no SplitFácil!")
        setNewCustomer(false);
        setStatusStep(result?.status);
        setAsaasDocuments(result?.asaasDocuments);
        formProps.form?.setFields([
          {
              name: `profileId`,
              value: result?.profileId
          },
          {
              name: `type`,
              value: result?.type
          },
          {
              name: `name`,
              value: result?.name
          },
          {
              name: `razaoSocial`,
              value: result?.razaoSocial,
          },
          {
              name: `countryCode`,
              value: result?.countryCode,
          },
          {
              name: `phone`,
              value: (result?.phone?.replace(/\D/g,'')==="11999999999") ? null : result?.phone,
          },
          {
              name: `email`,
              value: result?.email,
          },
          // {
          //     name: ['additionalInformation', 'birthDate'],
          //     value: result?.additionalInformation?.birthDate,
          // },
          // {
          //     name: ['additionalInformation', 'monthlyIncome'],
          //     value: result?.additionalInformation?.monthlyIncome,
          // },
          {
              name: ['address', 'zipCode'],
              value: result?.address?.zipCode?.replace(".","")
          },
          {
              name: ['address', 'street'],
              value: result?.address?.street
          },
          {
              name: ['address', 'number'],
              value: result?.address?.number
          },
          {
              name: ['address', 'complement'],
              value: result?.address?.complement
          },
          {
              name: ['address', 'neighborhood'],
              value: result?.address?.neighborhood
          },
          {
              name: ['address', 'city'],
              value: result?.address?.city
          },
          {
              name: ['address', 'state'],
              value: result?.address?.state
          },
        ]);
      } else if(result?.razaoSocial){           
        formProps.form?.setFields([
          {
              name: `type`,
              value: 'corporation'
          },
          {
              name: `name`,
              value: result?.fantasia
          },
          {
              name: `razaoSocial`,
              value: result?.razaoSocial,
          },
          // {
          //     name: `phone`,
          //     warnings: [`Preencher`]
          // },
          // {
          //     name: `email`,
          //     warnings: [`Preencher`]      
          // },
          // {
          //     name: `monthlyRevenue`,
          //     warnings: [`Preencher`]    
          // },
          {
              name: ['address', 'zipCode'],
              value: result?.endereco?.cep?.replace(".","")
          },
          {
              name: ['address', 'street'],
              value: result?.endereco?.logradouro
          },
          {
            name: ['address', 'number'],
            value: result?.endereco?.numero
          },
          {
            name: ['address', 'complement'],
            value: result?.endereco?.complemento
          },
          {
            name: ['address', 'neighborhood'],
            value: result?.endereco?.bairro
          },
          {
            name: ['address', 'city'],
            value: result?.endereco?.municipio
          },
          {
            name: ['address', 'state'],
            value: result?.endereco?.uf
          },
        ]);
        setCustomerType("corporation");
      }

    } else {
      console.log("findCpfCnpj invalid")
    }
  }

  const findCep = async(e: any, type : string) => {
    let value = e.target.value?.replace(/\D/g,'');
    console.log(value);

    if(value.length>=8){
      let response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
      console.log(response);
      if(response?.data){
        let cep = response?.data;
        formProps.form?.setFields([
          {
            name: ['address', 'street'],
            value: cep.logradouro
          },
          {
            name: ['address', 'neighborhood'],
            value: cep.bairro
          },
          {
            name: ['address', 'city'],
            value: cep.localidade
          },
          {
            name: ['address', 'state'],
            value: cep.uf
          },
        ]);
      }
    }
    validateForm();
  }  

  const validateForm = () => {

    let values : any = formProps.form?.getFieldsValue();
    console.log(formProps.form?.getFieldsValue());   
    setErrorMsg([]);    

    // validações genéricas
    const validateValues = (values: any) => {
      const validateField = (key: string, value: any, parentKey: string = '') : any => {
        // Construct the full key for nested objects
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
    
        // Check if the value is an object and not null
        if (typeof value === 'object' && value !== null) {
          // Recursively validate nested fields
          return Object.keys(value).every((nestedKey) => validateField(nestedKey, value[nestedKey], fullKey));
        }
    
        // Validate non-object values
        if (!value) {
          console.log(fullKey,key,value);
          if (
            ["profileId", "address.complement", "bankAccount.bankCode", "bankAccount.agencyDigit", "bankAccount.accountDigit","$u","$H"].includes(fullKey) ||
            (values.type === "individual" && ["razaoSocial"].includes(fullKey)) ||
            (values.type === "corporation" && ["additionalInformation.birthDate"].includes(fullKey)) ||
            ((values.type === "individual" || (values.type === "corporation" && !newCustomer)) && (registerType!=="companies") && ["password"].includes(fullKey))
          ) {
            // Optional fields, no validation required
            // return true;
          } else {
            // Required fields, add error message
            let translation = translate(`customers.fields.${key}`);
            addErrorMsg(`Revise o campo ${translation}`);
            console.log(errorMsg);
            // formProps.form?.setFields([{ name: key, errors: [`Oops! Verifique o campo ${translation}`] }]);
            // return false;
          }
        }
        return true;
      };
    
      // Validate all top-level fields
      return Object.keys(values).every((key) => validateField(key, values[key]));
    };
    
    validateValues(values);
        
    // validações senha
    let password = values.password;
    const strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if(registerType==="companies" && newCustomer && !strongPassword.test(password)){
      addErrorMsg("Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número");
      formProps.form?.setFields([ { name: "password", errors: ["Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número"] }, ]);
      return false;
    }

    // validações bancárias
    // TO-DO
  }
  
  const register = async() => {

    let values : any = formProps.form?.getFieldsValue();
    console.log(formProps.form?.getFieldsValue());   
    let cpfCnpj = values.cpfCnpj;    
    
    validateForm();
    setLoading(true);

    mutate({
        url: `${API_URL}/public/${registerType}`,
        method: "post",
        values: {
            companyHash: id,
            registerType,
            newCustomer,
            profileId: values.profileId,
            type: values.type,
            name: values.name,
            razaoSocial: values.razaoSocial || values.name,
            cpfCnpj: values.cpfCnpj,
            phone: values.phone,
            countryCode: values.countryCode,
            email: values.email,
            terms: values.terms,
            password: values.password,
            payerOrReceiver: registerType==="companies" ? null : values.payerOrReceiver,
            additionalInformation: values.additionalInformation,
            bankAccount: values.bankAccount,
            address: values.address
        },        
        successNotification: (data, values) => {
            setLoading(false);
            alert(`Seu cadastro foi submetido com sucesso!\n Faça o refresh da página e aguarde as instruções para os próximos passos.`);
            let urlRedirect = window.location.href?.includes("?cpfCnpj") ? "" : "?cpfCnpj=" + cpfCnpj;
            window.location.href = window.location.href + urlRedirect;
            return {
                message: `Cadastro enviado com sucesso`,
                description: "Aguarde as instruções para os próximos passos.",
                type: "success",
            };            
        },
        errorNotification: (data, values) => {
            setLoading(false);
            console.log(data,values);
            return {
                message: `Oops! Houve um erro`,
                description: "Não conseguimos enviar seus dados, tente novamente ou entre em contato com o nosso suporte.",
                type: "error",
            };
        }
    });    

  }

  const customFormat: DatePickerProps['format'] = (value) => `${value?.format('DD/MM/YYYY')}`;
  const disabledDate = (current : any) => {
    return current && current > new Date(new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000);
  };

  const phoneCodes = PHONE_CODES?.map(item => { return { label: `${item.name} (${item.code})`, value: item.iso, mask: item.mask}}) ?? [];

  const states = [
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" }
  ];

  const changePhoneCode = (value: any) => {
        console.log(`selected ${value}`);
        let findMask = phoneCodes?.find(item => item.value===value)?.mask;
        if(Array.isArray(findMask)){
            let mask =  findMask[findMask.length - 1]; // findMask.map((item: any) => { return { mask: item.replaceAll("9","0"), lazy: true } });
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0"));
        } else {            
            let mask = findMask; //Array.isArray(findMask) && findMask?.length ? findMask[findMask.length - 1] : findMask; // pega último mask por padrão
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0") || "(00) 00000-0000");
        }        
  };

  const currencyParser = (value : any) => {
      // Remove non-digit characters
      const numericValue = value.replace(/[^\d]/g, '');
    
      // If the input is empty or only contains non-digit characters, return 0
      if (!numericValue) {
          return 0;
      }

      // Get the last two digits as decimals
      const decimals = numericValue.slice(-2);
    
      // Get the integer part excluding the last two digits
      const integerValue = numericValue.slice(0, -2) || '0';
    
      // Concatenate the integer and decimal parts
      const formattedValue = integerValue + '.' + decimals;
    
      // Parse the formatted value to a float
      const parsedValue = parseFloat(formattedValue);
    
      // Return the parsed numeric value
      return isNaN(parsedValue) ? 0 : parsedValue;
  };   
                          
  const currencyFormatter = (value : any) => {
      return new Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL"
      }).format(value);
  };
  let bankCodes = BANK_CODES.filter(item => item.strCode).map((item : any) => { return { label: `${item.name} (${item.strCode})`, name: item.displayName, code: ("00" + item.strCode).slice(-3), value: ("00" + item.strCode).slice(-3), mask: item.mask}})

  const handleBankChange = (value: any, option: any) => {
    console.log("zzz",value,option);
    formProps.form?.setFields([
        {
        name: ["bankAccount","bank"], // required
        value: option?.label
        },
    ]);
  };      

  const changeCheckbox = (e: any) => {
    validateForm();
    console.log(`checked = ${e.target.checked}`);
  };

  const closeModal = () => {
    setOpen(false);
  }

  const validateBankAccount = async () => {
    // const form = Form.useFormInstance();
    console.log(formProps.form?.getFieldsValue());
    let values : any = formProps.form?.getFieldsValue();
    let fields = [["bankAccount","agency"],["bankAccount","agencyDigit"],["bankAccount","account"],["bankAccount","accountDigit"]];
    
    validateForm();

    if(values.bankAccount.bankCode && values.bankAccount.agency && values.bankAccount.account){
        try {
            let bank = BankAccountValidator.validate({
                bankNumber: "00" + values.bankAccount.bankCode.slice(-3),
                agencyNumber: values.bankAccount.agency,
                agencyCheckNumber: values.bankAccount.agencyDigit,
                accountNumber: values.bankAccount.account,
                accountCheckNumber: values.bankAccount.accountDigit,
            });
            // Se chegou até aqui, a conta bancária é válida
            console.log("validdd",bank);
            fields.map(item => {
                formProps.form?.setFields([
                    {
                    name: item, // required
                    errors: [],
                    },
                ]);
            });
        } catch (e : any) {
            // se não for válida, lança uma exceção
            console.log("invaliddd",e?.message);
            let fields = [];
            if(e?.message.includes("Agência inválida")) fields.push({ name: ["bankAccount","agency"], error: "Agência Inválida" })
            if(e?.message.includes("Dígito da agência inválido")) fields.push({ name: ["bankAccount","agencyDigit"], error: "Dígito da agência inválido" })
            if(e?.message.includes("Conta corrente inválida")) fields.push({ name: ["bankAccount","account"], error: "Conta corrente inválida" })
            if(e?.message.includes("Dígito da conta corrente inválido")) fields.push({ name: ["bankAccount","accountDigit"], error: "Dígito da conta corrente inválido" })

            fields.map(item => {
                formProps.form?.setFields([
                    {
                    name: item.name, // required
                    errors: [item.error],
                    },
                ]);
            });

            let errors = fields?.map(item => { addErrorMsg(item.error); return item.error; }) || [];
            console.log(errors?.join(" / "));
        }
    }
  }         

  const Address = (type : string) => {
    return (<>
        <Divider orientation="left" style={{ marginTop: 30}}>{type==="corporation" ? `Endereço Empresarial` : `Endereço Residencial`}</Divider>
        <Form.Item
          name={['address', 'zipCode']}
          label={translate("customers.fields.zipCode")}
          rules={[
            { required: true },
          ]}
        >
          <MaskedInput
            size="large"
            mask={'00000-000'}
            placeholder={"ex: 04040-000"}
            onChange={(value) => { findCep(value,type); }}
          />
        </Form.Item>
        <Form.Item
          // name="address"
          name={['address', 'street']}
          label={translate("customers.fields.address")}
          rules={[
            { required: true },
          ]}
        >
          <Input
            size="large"
            placeholder={"ex: Av Paulista"}
          />
        </Form.Item> 
        <Form.Item
          // name="addressNumber"
          name={['address', 'number']}
          label={translate("customers.fields.addressNumber")}
          rules={[
            { required: true },
          ]}
        >
          <Input
            size="large"
            placeholder={"ex: 100"}
          />
        </Form.Item> 
        <Form.Item
          // name="complement"
          name={['address', 'complement']}
          label={translate("customers.fields.complement")}
          rules={[
            { required: false },
          ]}
        >
          <Input
            size="large"
            placeholder={"ex: Ap 13"}
          />
        </Form.Item> 
        <Form.Item
          // name="neighborhood"
          name={['address', 'neighborhood']}
          label={translate("customers.fields.neighborhood")}
          rules={[
            { required: true },
          ]}
        >
          <Input
            size="large"
            placeholder={"ex: Centro"}
          />
        </Form.Item> 
        <Form.Item
          // name="city"
          name={['address', 'city']}
          label={translate("customers.fields.city")}
          rules={[
            { required: true },
          ]}
        >
          <Input
            size="large"
            placeholder={"ex: São Paulo"}
          />
        </Form.Item> 
        <Form.Item
            name={['address', 'state']}
            label={translate("customers.fields.state")}
            rules={[
              { required: true },
            ]}
        >
            <Select                            
                allowClear
                options={states}
                placeholder={translate("customers.fields.state")}
            />
        </Form.Item>
    </>);
  }

  const PageTitle = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        <FineFoodsIcon style={{ color: token.colorTextHeading }} />
      </div>
    );

  const CardTitle = (<>
    <Title
      level={3}
      style={{
        color: token.colorPrimaryTextHover,        
        ...titleStyles,
        marginTop: 30,
        marginBottom: 30,
      }}
    >
      Realize seu cadastro para ser parceiro da {registerType==="customers" ? ` ${company?.razaoSocial}` : `Split Fácil`}
    </Title>        
    </>
  );

  const CardContent = (<>
    <StepsProfileStatus statusStep={statusStep} direction={"horizontal"}/>
    <Card
      title={CardTitle}
      headStyle={headStyles}
      // bodyStyle={bodyStyles}
      style={{
        maxWidth: "900px",
        margin: "auto",
        // padding: "12.5px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.03)",
        backgroundColor: token.colorBgElevated,
      }}
      {...(contentProps ?? {})}
    >
    {(statusStep===0) ? (    
      <Spin spinning={loading}>
      <Form
        {...formProps}
        layout="vertical"
        // form={form}
        // onFinish={(values) => register(values)}
        requiredMark={false}
        initialValues={{
          profileId: null,
          name: "",          
          razaoSocial: "",
          cpfCnpj: cpfCnpj || "",
          email: "",
          countryCode: "BR",
          phone: "",
          payerOrReceiver: "all",
          type: "individual",
          password: null,
          additionalInformation: {
            birthDate: "",            
            monthlyIncome: 0
          },          
          bankAccount: {
            bank: "",
            bankCode: "",
            agency: "",
            agencyDigit: "",
            account: "",
            accountDigit: "",
            type: "conta_corrente",                    
          },
          terms: "",
          address: {
            zipCode: "",
            street: "",
            number: "",
            complement: "",
            neighborhood: "",
            city: "",
            state: "",
          }
        }}        
        onFinish={register}
      >        
        <Form.Item
          name="profileId"
          label={translate("customers.fields.profileId")}
          rules={[
            { required: false },
          ]}
          hidden={true}
        >
          <Input
            size="large"
          />
        </Form.Item>    
        <Form.Item
          name="payerOrReceiver"
          label={translate("customers.fields.payerOrReceiver")}
          rules={[
            { required: false },
          ]}
          hidden={true}
        >
          <Input
            size="large"
          />
        </Form.Item>        
        <Form.Item
            label={translate("customers.fields.type.title")}
            name="type"
            rules={[
                    {
                      required: true,
                    },
                  ]}
      >
              <Radio.Group onChange={handleTypeChange} disabled={newCustomer ? false : true}>
                  <Radio value="individual">{translate("customers.fields.type.individual")}</Radio><br/>
                  <Radio value="corporation">{translate("customers.fields.type.corporation")}</Radio>
              </Radio.Group>
        </Form.Item>     
        <Form.Item
          name="cpfCnpj"
          label={translate("customers.fields.cpfCnpj")}
          rules={[
            { required: true },
            {
              // ({ getFieldValue }) => ({
                  validator(_, value) {
                    console.log(value);
                    let validate = validateCPFOrCNPJ(value);
                    if (!validate) {
                      return Promise.reject('Oops! Número inválido, verifque novamente!');
                    }
                    return Promise.resolve();
                  },
              // }),
            }
          ]}
        >
          {/* <Input
            size="large"
            placeholder={translate("pages.customers.fields.cnpj", "cnpj")}
          /> */}
            <MaskedInput
                disabled={!newCustomer ? true : false}
                mask={customerType==="corporation" ? '00.000.000/0000-00' : '000.000.000-00'}
                onChange={findCpfCnpj}
            />                                    
        </Form.Item>   
        {(showForm) ? (<>
        <Form.Item
          name="name"
          label={customerType==="corporation" ? 'Nome Fantasia' : 'Nome Completo'}
          rules={[
            { required: true },
          ]}
        >
          <Input
            size="large"
            // onChage={changeName}
            placeholder={"Ex: Nome Fantasia (PJ) ou Nome Completo (PF)"}
          />
        </Form.Item>        
        <Form.Item
          name="razaoSocial"
          label={translate("customers.fields.razaoSocial")}
          rules={[
            { required: customerType==="corporation" ? true : false },
          ]}
          hidden={ customerType==="corporation" ? false : true }
        >
          <Input
            disabled={!newCustomer ? true : false}
            size="large"
            placeholder={"ex: Split Fácil Ltda ME"}
          />
        </Form.Item>        
        <Space.Compact style={{ width: '100%' }}>
            <Form.Item
              label={"País"}
              name="countryCode"
              style={{ width: '40%' }}
            >
              <Select
                  showSearch
                  // defaultValue="BR"
                  options={phoneCodes}
                  onChange={changePhoneCode}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
            </Form.Item>
            <Form.Item
                label={translate("customers.fields.phone")}
                name="phone"
                style={{ width: '60%' }}
                // tooltip={"Opcional: caso não tenha, use 11 99999-9999"}
                rules={[
                        { required: true, },
                        {
                          // ({ getFieldValue }) => ({
                              validator(_, value) {
                                console.log(value);
                                if ((!value?.replace(/\D/g,'') || Number(value?.replace(/\D/g,''))===0) || String(value?.replace(/\D/g,''))?.includes("99999")) {
                                  return Promise.reject('Oops! Insira um celular válido');
                                }
                                return Promise.resolve();
                              },
                          // }),
                        }
                      ]}
                        >
                      <MaskedInput
                          disabled={false}
                          mask={phoneMask || "(00) 00000-0000"}
                      />                         
            </Form.Item>
        </Space.Compact>
        <p style={{"color": "#888", marginTop: 10}}>📱 Importante: Insira um celular válido para receber o nosso suporte e atendimento.</p>
        <Form.Item
          name="email"
          label={translate("customers.fields.email")}
          rules={[
            { required: true },
            {
              type: "email",
              message: translate(
                "pages.customers.errors.validEmail",
                "E-mail inválido"
              ),
            },
          ]}
          // tooltip={"Atenção: será o acesso Master da conta da empresa"}
        >
          <Input
            size="large"
            placeholder={translate("pages.customers.fields.email", "Email")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={translate("customers.fields.password", "Password")}
          rules={[{ required: registerType==="companies" && newCustomer ? true : false }]}
          tooltip={"Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número"}
          hidden={registerType==="companies" && newCustomer ? false : true}
        >
          <Input type="password" placeholder="●●●●●●●●" minLength={6} size="large" />
        </Form.Item>        
        <Form.Item
            label={translate("customers.fields.birthDate")}
            name={["additionalInformation","birthDate"]}
            rules={[
                      {
                        required: customerType==="corporation" ? false : true
                      },
                  ]}
            tooltip={`Cadastro válido apenas para maiores de 18 anos`}
            hidden={customerType==="corporation" ? true : false}
            >
          <DatePicker
              // locale={locale}
              size="large"
              format={customFormat}
              // onChange={updateSplit}
              // onOk={updateSplit}
              // disabledDate={disabledDate}
              placeholder="DD/MM/AAAA"
              showToday={false}                                    
              style={{ width: "100%" }}
              disabledDate={disabledDate} // Set the disabledDate function
            />
        </Form.Item>        
        <Form.Item
          name={["additionalInformation","monthlyIncome"]}
          label={`${customerType==="corporation" ? 'Faturamento Mensal' : 'Renda Mensal'} (média)`}
          tooltip={"Fique tranquilo! Suas informações não serão compartilhadas com a empresa parceira, ela é apenas utilizada única e exclusivamente para o cadastramento junto as instituições de pagamentos."}
          rules={[
            { required: true, type: "number" },
            {
              // ({ getFieldValue }) => ({
                  validator(_, value) {
                    console.log(value);
                    if ((!value || value===0)) {
                      return Promise.reject('Oops! Insira um valor válido');
                    }
                    return Promise.resolve();
                  },
              // }),
            }
          ]}
        >
          {/* <Input
            size="large"
            placeholder={"1.000"}
          /> */}
          <InputNumber
            size="large"
            formatter={currencyFormatter}
            parser={currencyParser}
            // onBlur={currencyParser}
            // onChange={(value) => updateSplit()}
            value={0}
            style={{ width: '100%' }}  />            
        </Form.Item>        
        <p style={{"color": "#888", marginTop: 10}}>
          🔒 Fique tranquilo! Suas informações não serão compartilhadas com a empresa parceira, elas serão apenas utilizadas para o cadastramento junto as instituições de pagamentos.
        </p>
        {Address(customerType)}
        {(newCustomer) && (<>
        <Divider orientation="left" style={{ marginTop: 30}}><DollarOutlined/> Dados Bancários</Divider>
        <p style={{"color": "#888", marginTop: 10}}>
          * Esta conta deve ser da mesma titularidade do cadastro, caso contrário suas trasnferências serão invalidadas
        </p>
        <Form.Item
          label={translate("customers.fields.bank")}
          name={["bankAccount","bankCode"]}
          rules={[
              {
                  required: true,
              },
          ]}
        >
            <Select
              showSearch
              // defaultValue=""
              onChange={handleBankChange}
              // onSelect={(value) => {
              //     console.log("onSelect",value);
              //     setBank(value?.label);
              // }}
              options={bankCodes}
              optionFilterProp="children"
              filterOption={(input, option) =>
                  // (option?.label ?? '').includes(input) || (option?.name ?? '').includes(input)
                  (replaceAccents(option?.label ?? '').toLowerCase().indexOf(replaceAccents(input).toLowerCase()) >= 0) || (replaceAccents(option?.name ?? '').toLowerCase().indexOf(replaceAccents(input).toLowerCase()) >= 0)
              }
              filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              />
        </Form.Item>
        <Form.Item
          label={translate("customers.fields.bank")}
          name={["bankAccount","bank"]}
          rules={[
              {
                  required: false,
              },
          ]}
          hidden={true}
          >
          <Input onChange={validateBankAccount} />
        </Form.Item>
        <Space.Compact style={{ width: '100%' }}>
          <Form.Item
          label={translate("customers.fields.agency")}                        
          name={["bankAccount","agency"]}     
          style={{ width: '70%' }}
          rules={[
              {
                  required: true,
              },
          ]}
          >
          <Input onChange={validateBankAccount} />
          </Form.Item>
          <Form.Item
          label={translate("customers.fields.agencyDigit")}
          name={["bankAccount","agencyDigit"]}
          style={{ width: '30%' }}
          rules={[
              {
                  required: false,
              },
          ]}
          >
          <Input onChange={validateBankAccount} />                     
          </Form.Item>
        </Space.Compact>
        <Space.Compact style={{ width: '100%' }}>
          <Form.Item
          label={translate("customers.fields.account")}
          name={["bankAccount","account"]}
          style={{ width: '70%' }}
          rules={[
              {
                  required: true,
              },
              {
              // ({ getFieldValue }) => ({
                  validator(_, value) {
                    console.log(value);
                    if (!value) {
                      return Promise.reject('Oops! Insira o número da conta corrente');
                    } else if (value && value?.includes("-")) {
                      return Promise.reject(`Oops! O dígito da agência deve ser informado no campo ao lado`);
                    }
                    return Promise.resolve();
                  },
              // }),
              }
          ]}
          >
          <Input onChange={validateBankAccount} />
          </Form.Item>
          <Form.Item
          label={translate("customers.fields.accountDigit")}
          name={["bankAccount","accountDigit"]}
          style={{ width: '30%' }}
          rules={[
              {
                  required: false,
              },
          ]}
          >
          <Input onChange={validateBankAccount} />
          </Form.Item>
        </Space.Compact>
        <Form.Item
          label={"Tipo de Conta"}
          name={["bankAccount","type"]}
          rules={[
              {
                  required: true,
              },
          ]}
          >
          <Select
            showSearch
            defaultActiveFirstOption={true}
            placeholder=""
            optionFilterProp="children"
            onChange={validateBankAccount}
            // onChange={onChange}
            // onSearch={onSearch}
            filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
                {
                    value: 'conta_corrente',
                    label: 'Conta Corrente',
                },
                {
                    value: 'conta_poupanca',
                    label: 'Conta Poupança',
                },
                // {
                //     value: 'conta_corrente_conjunta',
                //     label: 'Conta Corrente Conjunta',
                // },
                // {
                //     value: 'conta_poupanca_conjunta',
                //     label: 'Conta Poupança Conjunta',
                // },
            ]}
          />
        </Form.Item> 
        </>)}
        <Form.Item
            style={{ marginTop: 20 }}
            label={translate("customers.fields.terms")}
            name="terms"
            valuePropName="checked"
            rules={[
                  {
                    validator: async (_, checked) => {
                                    if (!checked) {
                                      return Promise.reject(
                                        new Error("Você deve aceitar os termos para continuar, clique no botão acima."),
                                      );
                                    }
                              },
                  },
                                
              ]}
              // hidden={true}
              tooltip={"Termo de consentimento sobre os serviços, você deve aceitar para conseguir efetuar seu cadastro"}
        >          
          <Checkbox onChange={changeCheckbox} style={{ margin: '12.5px auto'}}>Estou de acordo com os termos e condições de uso da plataforma</Checkbox>                                          
        </Form.Item>
        <Link to="https://docs.google.com/document/d/e/2PACX-1vSTKHxxpm3t9FnNDrTz7kA9sXXNCrkVJ_0uJCtYHz9yVM8dYEfiEoYjy5t4kQDNcvccEH9_F26Mawsx/pub" target="_blank">Clique aqui para ler os termos de uso</Link>
        {(registerType==="companies" && newCustomer) && (<p>* Taxa de serviço: 2% sobre valor das cobranças</p>)}
        {(errorMsg?.length>0) && (<Alert showIcon message={`ATENÇÃO!`} description={<pre>{errorMsg?.join(" \n")}</pre>} type="error" style={{margin: '15px auto', color: "red"}}/>)}
        <Form.Item
          style={{
            marginTop: 20,
            marginBottom: 0,
          }}
        >
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            block
          >
            Cadastrar
          </Button>
        </Form.Item>
        </>) : (<><p style={{color: "grey", textAlign: "center"}}>Preencha para continuar</p></>)}
      </Form>   
      </Spin> 
    ) : (<>
      <ProfileStatus
        registerType={registerType}
        cpfCnpj={cpfCnpj}
        statusStep={statusStep}
        profileId={formProps.form?.getFieldValue("profileId")}
        asaasDocuments={asaasDocuments}
      />
    </>)
    }
    </Card>
  </>);

  const ModalContent = (<>
      <Modal
          forceRender
          getContainer={false} 
          open={open}
          closable={true}
          destroyOnClose={true}
          maskClosable={true}
          title={`Realize seu cadastro para ser parceiro da ${company?.razaoSocial}`}
          cancelText={""}
          okText={"CONTINUAR"}
          onOk={closeModal}
          onCancel={closeModal}
          width={1000}
          footer={[
          <Button type="primary" key="back" block onClick={closeModal}>
            CONTINUAR
          </Button>
          ]}
      >
        {/* <br/>
        <p>1. Informe seus dados cadastrais para ser um parceiro da empresa {company?.razaoSocial} e poder receber pagamentos.</p>
        <p>2. Após o envio dos dados, você receberá um link para anexar documentos adicionais</p>
        <p>3. Por fim, toda documentação passará por uma verificação de informações para habilitar seu cadastro</p>
        <br/> */}
        {(statusStep>=0) && (<StepsProfileStatus statusStep={statusStep} direction={"vertical"}/>)}        
        <p>🔒 Os dados de cadastro serão criptografados e servem única e exclusivamente para a validação cadastral junto com as instituições de pagamentos.</p>
        {/* <p>Ao submeter seus dados você está concordando com os termos de uso e serviço da plataforma</p> */}
        <p>O Split Fácil é homologado aos serviços financeiros da ASAAS Gestão Financeira Instituição de Pagamento S.A.</p>
      </Modal>
    </>
  )

  useEffect(() => {
    const fetchData = async () => {                
        if(id){
          let response = await axios.get(`${API_URL}/public/companies/${id}`,
              {
                  headers:{
                  'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                  }
          });
                      
          if(response?.data){
              console.log("API RESPONSE",response);
              setCompany(response?.data);
          } else {
              console.log("API ERROR",response);
          }
        }

        if(cpfCnpj){
          await findCpfCnpj({ value: cpfCnpj });
          formProps.form?.setFields([
            {
                name: "cpfCnpj", // required
                value: cpfCnpj
            },
          ]);
          setShowForm(true);
        } else {
          setLoading(false);
        }        
        console.log({cpfCnpj})

        setOpen(registerType==="customers" ? true : false);
    }
    fetchData();        

    // Cleanup function if needed
    return () => {
    // Cleanup code
    };

  }, [cpfCnpj, location]);

  return (
    <Layout
      // style={layoutStyles}
      // {...(wrapperProps ?? {})}
    >
      {ModalContent}  
      <Row
        justify="center"
        align="middle"
        style={{
          height: "auto",
          minHeight: "100vh"
        }}
      >
        <Col lg={24}>
            <>                            
              {PageTitle}                         
              {CardContent}
              {/* <Alert message="Você precisa completar o cadastro para receber transferências" type="success" style={{marginTop: 30}} closable/>
              <Alert message="Seus dados garantem segurança e transparência para sua conta" type="success" style={{marginTop: 15}} closable/> */}
            </>
        </Col>
      </Row>
    </Layout>
  );
};
