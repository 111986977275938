const minRoles = [
    {
        "resource": "metrics",
        "action": {
            "create": "viewer",
            "list": "viewer",
            "show": "viewer",
            "edit": "viewer",
        }
    },
    {
        "resource": "users",
        "action": {
            "create": "admin",
            "list": "admin",
            "show": "admin",
            "edit": "admin",
            "delete": "master",
        }
    },
    {
        "resource": "companies",
        "action": {
            "create": "master",
            "list": "viewer",
            "show": "viewer",
            "edit": "owner",
            "delete": "master",
        }
    },
    {
        "resource": "userCompanies",
        "action": {
            "create": "admin",
            "list": "admin",
            "show": "admin",
            "edit": "admin",
            "delete": "owner",
        }
    },
    {
        "resource": "companyAccounts",
        "action": {
            "create": "owner",
            "list": "manager",
            "show": "manager",
            "edit": "admin",
            "delete": "owner",
        }
    },
    {
        "resource": "customers",
        "action": {
            "create": "manager",
            "list": "viewer",
            "show": "viewer",
            "edit": "admin",
            "delete": "admin",
        }
    },
    {
        "resource": "payers",
        "action": {
            "create": "manager",
            "list": "viewer",
            "show": "viewer",
            "edit": "admin",
            "delete": "admin",
        }
    },
    {
        "resource": "accounts",
        "action": {
            "create": "admin",
            "list": "manager",
            "show": "manager",
            "edit": "admin",
            "delete": "admin",
        }
    },
    {
        "resource": "invoices",
        "action": {
            "create": "admin",
            "list": "viewer",
            "show": "viewer",
            "edit": "admin",
            "delete": "admin",
        }
    },
    {
        "resource": "invoiceStatuses",
        "action": {
            "show": "viewer",
            "list": "viewer",
        }
    },
    {
        "resource": "tags",
        "action": {
            "create": "manager",
            "list": "manager",
            "show": "manager",
            "edit": "manager",
            "delete": "admin",
        }
    },
    {
        "resource": "customerTags",
        "action": {
            "create": "manager",
            "list": "manager",
            "show": "manager",
            "edit": "manager",
            "delete": "admin",
        }
    },
    {
        "resource": "logs",
        "action": {
            "create": "viewer",
            "list": "viewer",
            "show": "viewer",
            "edit": "viewer",
        }
    },
    {
        "resource": "preferences",
        "action": {
            "create": "viewer",
            "list": "viewer",
            "show": "viewer",
            "edit": "viewer",
        }
    }    
]

export const checkRoles = (role: any, resource: any, action: any) => {
    return new Promise (async(resolve,reject) => {
        try {                
                console.log({ role, resource, action });

                {/* 
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore */}
                const minRole : any = minRoles.find(role => role.resource===resource)?.action[action] || "admin";

                const companyId = localStorage.getItem("companyId");
                let companyRole = role
                let allowed = false;
                // console.log({companyId, minRole, role});
            
                if(companyId && minRole && companyRole && role!=="master"){
                    switch (minRole) {
                        // case "master":
                        //     if(req.user.master) allowed = true;
                        //     break;      
                        case "owner":
                            if(["master","owner"].includes(companyRole)) allowed = true;
                            break;      
                        case "admin":
                            if(["master","owner","admin"].includes(companyRole)) allowed = true;
                            break; 
                        case "manager":
                            if(["master","owner","admin","manager"].includes(companyRole)) allowed = true;
                            break;  
                        case "viewer":
                            if(["master","owner","admin","manager","viewer"].includes(companyRole)) allowed = true;
                            break;                         
                    }
                    if(allowed) return resolve(true);
                } else if(role==="master"){
                    return resolve(true);
                }
            
                return resolve(false);
            
        } catch(error){
            console.log(error);
            return resolve(false);
        }
    });
}