import { useState, useEffect } from "react";
import { TOKEN_KEY, API_URL, PHONE_CODES } from "../../constants";
import { useApiUrl, useCustom, useCustomMutation, useTranslate, useLink } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Row, Col, Input, Card, Space, Button, Typography, Tooltip, Select, Switch, Alert, Divider } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { update } from "lodash";
import MaskedInput from "antd-mask-input";
import axios from "axios";
const { Text } = Typography;

export const SupportPage: React.FC = () => {
    
    const { t } = useTranslation();
    const Link = useLink();

    return (<>
        <Card title={`Entre em contato`}>
            <p>Atendimento de Segunda à Sexta das 10h as 17h</p>
            <Button type={"primary"} icon={<WhatsAppOutlined />} style={{ background: "#25d366"}}>
                <Link to="https://api.whatsapp.com/send?phone=5511991554635&text=" target="_blank" style={{marginTop:20, textAlign: "center"}}>
                +55 11 99155-4635
                </Link>
            </Button>
        </Card>

        <Card title={`Termos e Condições de Uso`} style={{marginTop: 20}}>
            <Link to="https://docs.google.com/document/d/e/2PACX-1vSTKHxxpm3t9FnNDrTz7kA9sXXNCrkVJ_0uJCtYHz9yVM8dYEfiEoYjy5t4kQDNcvccEH9_F26Mawsx/pub" target="_blank" style={{marginTop:20, textAlign: "center"}}>
            Leia os Termos de uso
            </Link>
        </Card>        
        </>);
};
