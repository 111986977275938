import { TOKEN_KEY, API_URL, PHONE_CODES } from "./../../constants";
import { useMemo, useState, useEffect } from "react";
import { IResourceComponentsProps, useTranslate, useCustom } from "@refinedev/core";

import { Create, useForm } from "@refinedev/antd";

import {
    Form,
    Input,
    Avatar,
    Row,
    Col,
    Typography,
    Space,
    Select,
    Radio,
    InputProps,
} from "antd";

import MaskedInput from "antd-mask-input";

const { Text } = Typography;

import { IUser } from "interfaces";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm<IUser>();
    // const [customerPhone, setCustomerPhone] = useState('');
    const [phoneMask, setPhoneMask] = useState('');
    // const [phoneCodes, setphoneCodes] = useState([] as any[]);

    // Pega userRoles
    const urlCustomers = `${API_URL}/userRoles`;
    const { data } = useCustom<{data: any;}>({ url: urlCustomers, method: "get"});
    const result : any = data?.data;                
    console.log(result);
    const roles = result?.filter((item: any) => !["owner"].includes(item.value))?.map(
        (item : any) => {
            return {
                value: item.value,
                label: item.label,
                // disabled: !item.active ? true : false
                }
        }
    );

    const phoneCodes = PHONE_CODES?.map(item => { return { label: `${item.name} (${item.code})`, value: item.iso, mask: item.mask}}) ?? [];  

    const changePhoneCode = (value: any) => {
        console.log(`selected ${value}`);
        let findMask = phoneCodes?.find(item => item.value===value)?.mask;
        if(Array.isArray(findMask)){
            let mask =  findMask[findMask.length - 1]; // findMask.map((item: any) => { return { mask: item.replaceAll("9","0"), lazy: true } });
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0"));
        } else {            
            let mask = findMask; //Array.isArray(findMask) && findMask?.length ? findMask[findMask.length - 1] : findMask; // pega último mask por padrão
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0") || "(00) 00000-0000");
        }        
    };

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
        >
            <Form
                {...formProps}
                layout="vertical"
                initialValues={{
                    countryCode: "BR",
                    active: true,
                }}
            >
                <Row gutter={[64, 0]} wrap>
                    {/* <Col xs={24} lg={6}>
                        <Space
                            direction="vertical"
                            align="center"
                            style={{
                                width: "100%",
                                textAlign: "center",
                                marginBottom: "16px",
                            }}
                        >
                            <Avatar
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    maxWidth: "256px",
                                }}
                                src="/images/default-store-img-lg.png"
                                alt="User Location"
                            />
                            <Text
                                strong
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {t("users.selectLocation")}
                            </Text>
                        </Space>
                    </Col> */}
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("users.fields.name")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("users.fields.email")}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: "email",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Space.Compact style={{ width: '100%' }}>
                        <Form.Item
                            label={"País"}
                            name="countryCode"
                            style={{ width: '40%' }}
                        >
                            <Select
                                showSearch
                                defaultValue="BR"
                                options={phoneCodes}
                                onChange={changePhoneCode}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label={t("users.fields.phone")}
                            name="phone"
                            style={{ width: '60%' }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <MaskedInput
                                    disabled={false}
                                    mask={phoneMask || "(00) 00000-0000"}
                                    >
                                    {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
                                    {/* {(props: InputProps) => <Input {...props} />} */}
                            </MaskedInput>                              
                        </Form.Item>
                        </Space.Compact>
                        <Form.Item
                            label={t("users.filter.role.label")}
                            name="role"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            tooltip={"Tipo de acesso ao sistema"}
                        >
                            <Select
                                allowClear
                                placeholder={t("users.filter.role.placeholder")}
                                options={roles}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        {/* <Form.Item
                            label={t("users.fields.address")}
                            name={["address", "text"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.TextArea rows={8} />
                        </Form.Item> */}                        
                        {/* <Form.Item
                            label={t("users.fields.active.title")}
                            name="active"
                        >
                            <Radio.Group>
                                <Radio value={true}>{t("users.fields.active.true")}</Radio>
                                <Radio value="{false}">{t("users.fields.active.false")}</Radio>
                            </Radio.Group>
                        </Form.Item> */}
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
