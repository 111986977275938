import { useState, useEffect } from "react";
import {
    useGetLocale,
    useSetLocale,
    useGetIdentity,
    useTranslate,
    useList,
} from "@refinedev/core";
import { Link } from "react-router-dom";
import { SearchOutlined, DownOutlined, PlusOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";

import {
    Dropdown,
    Input,
    Avatar,
    Typography,
    Space,
    Grid,
    Row,
    Col,
    AutoComplete,
    Layout as AntdLayout,
    Button,
    theme,
    MenuProps,
} from "antd";

import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

const { Header: AntdHeader } = AntdLayout;
const { useToken } = theme;
const { Text } = Typography;
const { useBreakpoint } = Grid;

import { IInvoice, ICustomer, ICourier, IIdentity } from "interfaces";
import { HeaderTitle } from "./styled";
import { useConfigProvider } from "context";
import { IconMoon, IconSun } from "components/icons";
import { authProvider } from "authProvider";
import { checkRoles } from "accessControl";

interface IOptionGroup {
    value: string;
    label: string | React.ReactNode;
}

interface IOptions {
    label: string | React.ReactNode;
    options: IOptionGroup[];
}

export const Header: React.FC = () => {
    const { token } = useToken();
    const { mode, setMode } = useConfigProvider();
    const { i18n } = useTranslation();
    const locale = useGetLocale();
    const changeLanguage = useSetLocale();
    const { data: user } = useGetIdentity<IIdentity>();
    const screens = useBreakpoint();
    const t = useTranslate();

    const currentLocale = locale();
    console.log("currentLocale",currentLocale);

    const [currentCompanyFee, setCompanyFee] = useState(null);
    const [currentCompanyHash, setCompanyHash] = useState(null);
    const [currentCompany, setCompany] = useState(null);
    const [currentRole, setRole] = useState(null);
    
    const companyRoles = user?.roles;
    console.log("companyRoles",companyRoles);    

    const renderTitle = (title: string, link: string) => (
        <HeaderTitle>
            <Text style={{ fontSize: "16px" }}>{title}</Text>
            <Link to={`/${link}`}>{t("search.more")}</Link>
        </HeaderTitle>
    );

    const renderItem = (title: string, link: string, imageUrl: string) => ({
        value: title,
        label: (
            <Link to={link} style={{ display: "flex", alignItems: "center" }}>
                {/* <Avatar size={64} src={imageUrl} style={{ minWidth: "64px" }} /> */}
                <Text style={{ marginLeft: "16px" }}>{title}</Text>
            </Link>
        ),
    });

    const [value, setValue] = useState<string>("");
    const [options, setOptions] = useState<IOptions[]>([]);

    const { refetch: refetchInvoices } = useList<IInvoice>({
        resource: "invoices",
        config: {
            filters: [{ field: "q", operator: "contains", value }],
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const invoiceOptionGroup = data.data.map((item) =>
                    renderItem(
                        `${item?.customer?.title} / #${item?.orderNumber}`,                        
                        `/invoices/show/${item.id}`,
                        "/images/default-order-img.png",
                    ),
                );
                if (invoiceOptionGroup.length > 0) {
                    setOptions((prevOptions) => [
                        ...prevOptions,
                        {
                            label: renderTitle(t("invoices.invoices"),"invoices"),
                            options: invoiceOptionGroup,
                        },
                    ]);
                }
            },
        },
    });

    const { refetch: refetchCustomers } = useList<ICustomer>({
        resource: "customers",
        config: {
            filters: [{ field: "q", operator: "contains", value }],
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const customerOptionGroup = data.data.map((item) =>
                    renderItem(
                        `${item.name} | ${item.razaoSocial}`,
                        `/customers/edit/${item.id}`,
                        "/images/default-store-img.png",
                    ),
                );
                if (customerOptionGroup.length > 0) {
                    setOptions((prevOptions) => [
                        ...prevOptions,
                        {
                            label: renderTitle(t("customers.customers"),"customers"),
                            options: customerOptionGroup,
                        },
                    ]);
                }
            },
        },
    });    

    useEffect(() => {
        setOptions([]);
        // refetchInvoices();
        refetchCustomers();
        setCompany(localStorage.getItem("companyId") && localStorage.getItem("companyId")!==undefined ? localStorage.getItem("companyId") : (companyRoles ? companyRoles[0]?.company?.id : null));
        setCompanyHash(localStorage.getItem("companyHash") && localStorage.getItem("companyHash")!==undefined ? localStorage.getItem("companyHash") : (companyRoles ? companyRoles[0]?.company?.hash : null));        
        setCompanyFee(localStorage.getItem("companyFee") && localStorage.getItem("companyFee")!==undefined ? localStorage.getItem("companyFee") : (companyRoles ? companyRoles[0]?.company?.defaultFee : 2));
        setRole(localStorage.getItem("role") && localStorage.getItem("role")!==undefined ? localStorage.getItem("role") : (companyRoles ? companyRoles[0]?.role : null));
    }, [value]);

    const menuItems: MenuProps["items"] = [...(i18n.languages || [])]
        .sort()
        .map((lang: string) => ({
            key: lang,
            onClick: () => changeLanguage(lang),
            icon: (
                <span style={{ marginRight: 8 }}>
                    <Avatar size={16} src={`/images/flags/${lang}.svg`} />
                </span>
            ),
            label: lang === "en" ? "English" : "Português",
        }));

    const menuCompanies: MenuProps["items"] = (companyRoles || []).sort()
        .map((item: any) => ({
            key: item.company.id,
            onClick: () => changeCompany(item),
            label: item.company?.name
        }));


    const changeCompany = (item : any) => {
        console.log(item);
        setCompany(item.id);
        setRole(item.role);
        setCompanyFee(item?.company?.defaultFee ?? 2);
        setCompanyHash(item?.company?.hash ?? null);
        localStorage.setItem("companyFee", item.company?.defaultFee ?? 2);
        localStorage.setItem("companyHash", item.company?.hash ?? 2);
        localStorage.setItem("companyId", item.company?.id);
        localStorage.setItem("role", item.role);
        // location.reload();
        window.location.href = '/'; // full reload para home
    }        

    const handleClick = () => {
        // Redirect to the specific page
        window.location.href = '/invoices/create';
    };

    const items = [
        {
            key: '1',
            label: (
                <a rel="noopener noreferrer" href="/preferences">
                    {<SettingOutlined />} Preferências
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a rel="noopener noreferrer"
                    onClick={() => {
                        authProvider.logout(null);
                    }}
                    href="/login">
                    {<LogoutOutlined />} Logout
                </a>
            ),
        }
    ];

    let canAccess = ["admin","owner"].includes(localStorage.getItem("role") ?? "");
            
    return (
        <AntdHeader
            style={{
                backgroundColor: token.colorBgElevated,
                padding: "0 24px",
                position: "sticky",
                top: 0,
                zIndex: 1,
                borderBottom: "1px solid rgb(217, 217, 217)"
            }}
        >
            <Row
                align="middle"
                style={{
                    justifyContent: screens.sm ? "space-between" : "end",
                }}
            >
                <Col xs={0} sm={8}>
                    <AutoComplete
                        style={{
                            width: "100%",
                            maxWidth: "300px",
                        }}
                        options={options}
                        filterOption={false}
                        onSearch={debounce(
                            (value: string) => setValue(value),
                            300,
                        )}
                    >
                        <Input
                            size="large"
                            // placeholder={t("search.placeholder")}
                            placeholder="Procure por um cliente"
                            suffix={<SearchOutlined />}
                        />
                    </AutoComplete>
                </Col>
                <Col>
                    <Space size="middle" align="center">
                        {/* <Button
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            type="default"
                            icon={mode === "light" ? <IconMoon /> : <IconSun />}
                            onClick={() => {
                                setMode(mode === "light" ? "dark" : "light");
                            }}
                        /> */}
                        {(canAccess) && (
                        <Button
                            style={{
                                // display: "flex",
                                // display: screens.lg ? "flex" : "none",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                handleClick()
                            }}
                        >
                            { screens.lg ? "Criar Cobrança" : "" }
                        </Button>
                        )}

                        <Dropdown
                            menu={{
                                items: menuCompanies,
                                selectedKeys: currentCompany ? [currentCompany] : [],
                            }}
                        >
                            <Button
                                style={{ color: "inherit" }}
                                onClick={(e) => e.preventDefault()}
                            >
                                <Space> 
                                    <div
                                        style={{
                                            display: screens.lg ? "block" : "flex",
                                            width: screens.lg ? "100%" : "125px",
                                            overflow: screens.lg ? "auto" : "hidden",
                                        }}
                                    >   
                                        {/* {currentCompany} */}
                                        {companyRoles?.find((item : any) => Number(item?.company.id)===Number(currentCompany))?.company?.name}
                                        <DownOutlined
                                            style={{
                                                fontSize: "12px",
                                                marginLeft: "6px",
                                            }}
                                        />
                                    </div>
                                </Space>
                            </Button>
                        </Dropdown>

                        {/* <Dropdown
                            menu={{
                                items: menuItems,
                                selectedKeys: currentLocale ? [currentLocale] : [],
                            }}
                        >
                            <a
                                style={{ color: "inherit" }}
                                onClick={(e) => e.preventDefault()}
                            >
                                <Space>
                                    <div
                                        style={{
                                            display: screens.lg
                                                ? "block"
                                                : "none",
                                        }}
                                    >
                                        {currentLocale === "en"
                                            ? "English"
                                            : "Português"}
                                        <DownOutlined
                                            style={{
                                                fontSize: "12px",
                                                marginLeft: "6px",
                                            }}
                                        />
                                    </div>
                                </Space>
                            </a>
                        </Dropdown> */}

                        <Dropdown menu={{ items }}>
                            
                            {/* <Text
                                ellipsis
                                strong
                                style={{
                                    display: "flex",
                                }}
                            >
                                {user?.name?.split(" ")?.[0]}
                            </Text> */}
                            <Avatar
                                size="large"
                                // src={user?.avatar}
                                alt={user?.name}
                            >
                                {user?.name?.substring(0,1).toUpperCase()}
                            </Avatar>

                        </Dropdown>
                    </Space>
                </Col>
            </Row>
        </AntdHeader>
    );
};
