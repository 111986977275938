import { TOKEN_KEY, API_URL } from "./../../constants";
import {
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    HttpError,
    useNavigation,
    useCustom
} from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    BooleanField,
    ShowButton,
    EditButton,
    CreateButton
} from "@refinedev/antd";

import { SearchOutlined, FormOutlined, MoreOutlined } from "@ant-design/icons";

import {
    Table,
    Avatar,
    Menu,
    Card,
    Input,
    Form,
    DatePicker,
    Button,
    Select,
    FormProps,
    Row,
    Col,
    Dropdown,
    Space
} from "antd";

import { IUser, IUserFilterVariables } from "interfaces";

export const UserList: React.FC<IResourceComponentsProps> = () => {
    
    const { edit, show } = useNavigation();
    
    const { tableProps, searchFormProps } = useTable<
        IUser,
        HttpError,
        IUserFilterVariables
    >({
        initialSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, status, createdAt, role, active } = params;

            filters.push({
                field: "q",
                operator: "eq",
                value: q,
            });

            filters.push(
                {
                    field: "createdAt",
                    operator: "gte",
                    value: createdAt
                        ? createdAt[0].startOf("day").toISOString()
                        : undefined,
                },
                {
                    field: "createdAt",
                    operator: "lte",
                    value: createdAt
                        ? createdAt[1].endOf("day").toISOString()
                        : undefined,
                },
            );

            filters.push({
                field: "role",
                operator: "eq",
                value: role,
            });

            filters.push({
                field: "active",
                operator: "eq",
                value: active,
            });

            // filters.push({
            //     field: "statusText",
            //     operator: "eq",
            //     value: status,
            // });

            return filters;
        },
        syncWithLocation: false,
    });

    const t = useTranslate();

    const TrueIcon = () => <span>✅</span>;

    const FalseIcon = () => <span>❌</span>;

    const moreMenu = (id: number) => (
        <Menu mode="vertical">
            <Menu.Item
                key="1"
                style={{
                    fontSize: 15,
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{ color: "green", fontSize: "15px" }}
                    />
                }
                onClick={() => edit("users", id)}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="2"
                style={{
                    fontSize: 15,
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{ color: "green", fontSize: "15px" }}
                    />
                }
                onClick={() => show("users", id)}
            >
                {t("buttons.show")}
            </Menu.Item>
        </Menu>
    );

    console.log(tableProps);

    return (
        <Row gutter={[16, 16]}>
            <Col
                xl={6}
                lg={24}
                xs={24}
                style={{
                    marginTop: "48px",
                }}
            >
                <Card title={t("users.filter.title")}>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>

            <Col xl={18} xs={24}>
                <List
                headerProps={{
                    extra: 
                    // <RefreshButton
                    //     resource="invoices"
                    // />
                    <CreateButton>Inserir Funcionário</CreateButton>
                }}>
                    <Table {...tableProps} rowKey="id">
                        <Table.Column
                            key="name"
                            dataIndex="name"
                            title={t("users.fields.name")}
                        />
                        <Table.Column
                            key="email"
                            dataIndex="email"
                            title={t("users.fields.email")}
                        />
                        {/* <Table.Column
                            key="phone"
                            dataIndex="phone"
                            title={t("users.fields.phone")}
                        /> */}
                        <Table.Column
                            key="roleText"
                            dataIndex="roleText"
                            title={t("users.fields.role")}
                        />
                        <Table.Column
                            dataIndex="active"
                            title={t("users.fields.active.title")}
                            align="center"
                            render={(value) => <BooleanField
                                trueIcon={<TrueIcon />}
                                falseIcon={<FalseIcon />}
                                value={value} />}
                        />
                        <Table.Column
                            key="createdAt"
                            dataIndex="createdAt"
                            title={t("users.fields.createdAt")}
                            render={(value) => (
                                <DateField value={value} format="LLL" />
                            )}
                            sorter
                        />
                        <Table.Column
                            key="lastLogin"
                            dataIndex="lastLogin"
                            title={t("users.fields.lastLogin")}
                            render={(value) => (
                                <DateField value={value} format="LLL" />
                            )}
                            sorter
                        />
                        {/* <Table.Column<IUser>
                            fixed="right"
                            title={t("table.actions")}
                            dataIndex="actions"
                            key="actions"
                            align="center"
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenu(record.id)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        /> */}
                        <Table.Column<IUser>
                        title="Opções"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="large"
                                    recordItemId={record.id}
                                />
                                <ShowButton
                                    hideText
                                    size="large"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                        />
                        {/*  
                        <Table.Column
                            key="gsm"
                            dataIndex="gsm"
                            title={t("users.fields.gsm")}
                        />
                        <Table.Column
                            align="center"
                            key="avatar"
                            dataIndex={["avatar"]}
                            title={t("users.fields.avatar.label")}
                            render={(value) => <Avatar src={value[0].url} />}
                        />
                        <Table.Column
                            key="firstName"
                            dataIndex="firstName"
                            title={t("users.fields.firstName")}
                        />
                        <Table.Column
                            key="lastName"
                            dataIndex="lastName"
                            title={t("users.fields.lastName")}
                        />
                        <Table.Column
                            key="gender"
                            dataIndex="gender"
                            title={t("users.fields.gender.label")}
                            render={(value) =>
                                t(`users.fields.gender.${value}`)
                            }
                        />
                        <Table.Column
                            key="isActive"
                            dataIndex="isActive"
                            title={t("users.fields.isActive.label")}
                            render={(value) => <BooleanField value={value} />}
                        />
                        <Table.Column
                            key="createdAt"
                            dataIndex="createdAt"
                            title={t("users.fields.createdAt")}
                            render={(value) => (
                                <DateField value={value} format="LLL" />
                            )}
                            sorter
                        />
                        <Table.Column<IUser>
                            fixed="right"
                            title={t("table.actions")}
                            render={(_, record) => (
                                <ShowButton hideText recordItemId={record.id} />
                            )}
                        />
                        */}
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
    const t = useTranslate();

    const { RangePicker } = DatePicker;

    // Pega userRoles
    const urlCustomers = `${API_URL}/userRoles`;
    const { data } = useCustom<{data: any;}>({ url: urlCustomers, method: "get"});
    const result : any = data?.data;                
    console.log(result);
    const roles = result?.map(
        (item : any) => {
            return {
                value: item.value,
                label: item.label,
                // disabled: !item.active ? true : false
                }
        }
    );

    return (
        <Form layout="vertical" {...props.formProps}>
            <Row gutter={[10, 0]} align="bottom">
                <Col xs={24} xl={24} md={8}>
                    <Form.Item label={t("users.filter.search.label")} name="q">
                        <Input
                            placeholder={t("users.filter.search.placeholder")}
                            prefix={<SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                {/* <Col xs={24} xl={24} md={12}>
                    <Form.Item
                        label={t("users.filter.createdAt.label")}
                        name="createdAt"
                    >
                        <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col> */}
                <Col xs={24} xl={24} md={8}>
                    <Form.Item
                        label={t("users.filter.role.label")}
                        name="role"
                    >
                        <Select
                            allowClear
                            placeholder={t("users.filter.role.placeholder")}
                            options={roles}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} xl={24} md={8}>
                    <Form.Item
                        label={t("users.filter.active.label")}
                        name="active"
                    >
                        <Select
                            allowClear
                            placeholder={t("users.filter.active.placeholder")}
                            options={[
                                {
                                    label: t("users.filter.active.true"),
                                    value: "true",
                                },
                                {
                                    label: t("users.filter.active.false"),
                                    value: "false",
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} xl={24} md={8}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                        >
                            {t("users.filter.submit")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
