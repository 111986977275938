import styled from "@emotion/styled";
import { PageHeader as AntdPageHeader } from "@refinedev/antd";

import { Col } from "antd";

export const InfoText = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 25px;

    @media screen and (max-width: 768px) {
        margin-bottom: 25px;
    }
`;

export const InfoBox = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 13px;
    margin-left: 20px;
    border-radius: 10px;

    @media screen and (max-width: 1199px) {
        margin-right: 12px;
        margin-left: 0;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
        width: 100%;
    }
`;

export const InfoBoxText = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        margin-bottom: 25px;
    }
`;

export const BoxContainer = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    svg {
        margin-right: 10px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 35px;
        flex-direction: column;
    }
`;